import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalPaging } from '@pineapplelab/paging';
import { isNullOrUndefined, isObject } from '@pineapplelab/util';
import { Category } from 'src/app/models/firebase/category';
import { Menu } from 'src/app/models/firebase/menu';
import { Project } from 'src/app/models/firebase/project';
import { ObjectSubmodel } from 'src/app/models/objectSubmodel';
import { Paging } from 'src/app/models/paging';
import { Table } from 'src/app/models/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MenuService } from 'src/app/services/menu/menu.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

	@ViewChild('activeColumn') activeColumn: ElementRef;
	@ViewChild('name') name: ElementRef;

	user = this.authService.auth.user;
	model: Menu = new Menu();
	project = this.authService.projectId;

	categoryPaging = new Paging<Category>({
		...Category.PAGING,
		size: 10,
		mapElements: doc => this.mapElements(doc),
		sizeOptions: [5, 10, 20],
		orderBy: 'name'
	});

	table = new Table({ paging: this.categoryPaging, columns: [] })

	modelRelation: { [relationId: string]: ObjectSubmodel } = {};
	categoryToAdd: ObjectSubmodel[] = [];
	pagesMount: number = 0;

	modelPreview: any = null;
	changeModel: any = null;
	changeClick: boolean = false;
	changeView: boolean = false;
	videoUrl: string = null;

	constructor(
		private dialogRef: MatDialogRef<AddCategoryComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { model: Menu, modelRelation: { [relationId: string]: ObjectSubmodel } },
		protected toasterService: ToasterService,
		protected authService: AuthService,
		private menuService: MenuService,
		public dialog: MatDialog,
	) { }

	ngOnInit(): void {
		this.categoryPaging.orderOptions = [
			{ field: 'name', label: 'Name', placeholder: 'Filter by name', dataType: LocalPaging.dataType.string },
		];

		this.categoryPaging.preconditions = [
			{ fieldPath: 'is_deleted', filterOp: '==', value: false },
			{ fieldPath: 'created_by', filterOp: '==', value: this.user?.id },
		];

		this.categoryPaging.subCollections = [
			{ collection: Project.PATH, docKey: this.project },
		];

		this.categoryPaging.loadData();
	}

	ngAfterViewInit(): void {
		this.table.columns.splice(0, 0, { field: '__active', label: '', cssClass: 'demo-position', template: this.activeColumn });
		this.table.columns.splice(2, 0, { field: 'name', label: 'Name', template: this.name });

		if (isObject(this.data) && isObject(this.data.model)) {
			this.loadModelData();
		}
		this.table.initialized = true
	}

	mapElements(doc: any) {
		const category: Category & { active?: boolean } = new Category(doc);

		category.active = !isNullOrUndefined(this.modelRelation[doc.id]);

		return category;
	}

	addOrRemoveRelation(item: Category) {
		const index = this.categoryToAdd.findIndex(c => c.id === item.id);
		if (index !== -1) {
			this.categoryToAdd.splice(index, 1);
			delete this.modelRelation[item.id];
			return;
		}

		const newRelation: any = {
			id: item.id,
			name: item.name,
			isActive: true,
			reference: this.menuService.docReference(this.project, item.id),
		}

		this.categoryToAdd.push(newRelation);
		this.modelRelation[item.id] = newRelation;
	}

	loadModelData() {
		this.modelRelation = this.data.modelRelation;
		this.model = this.data.model;
		this.categoryToAdd.push(...this.model.categories)

	}

	async saveInClass() {
		this.model.categories = [...this.categoryToAdd];
		const isEmpty = Object.keys(this.modelRelation).length === 0;
		if (!isEmpty) {
			this.toasterService.show('dish added', 'Success');
			this.dialogRef.close();
		}
		if (isEmpty) {
			this.toasterService.show('dishes no added', 'Info');
		}
	}

}
