import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fontsEnum } from 'src/app/enums/fonts.enum';

@Component({
  selector: 'app-select-font',
  templateUrl: './select-font.component.html',
  styleUrls: ['./select-font.component.scss']
})

export class SelectFontComponent {

	@Input() model: any

	@Output() selectFont = new EventEmitter<string>();

	fonts = fontsEnum

	selectedFont(font: any) {
		this.selectFont.emit(font)
	}

}
