import { FBase } from "./firebase.base";
import { Paging } from "../paging";
import { isObject } from "@pineapplelab/util";

export class Project extends FBase {

	name: string = null;

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}
}

export namespace Project {
	export const PATH = 'projects';

	export const PAGING = new Paging<Project>(<Paging.IPaging>{
		collection: PATH,
	});
}
