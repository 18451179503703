import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNoEmptyArray, isNullOrUndefined } from '@pineapplelab/util';
import { NavigationLink } from '../../interfaces/i-navigation-link';
import { Permissions } from 'src/app/enums/permissions.enum';

@Injectable({
	providedIn: 'root'
})

export class NavigationService {

	constructor() {}

	getBackOfficeLinks(projectId: string): NavigationLink[] {
		return [
			{
				// path: `/dashboard/${projectId}/menu`,
				path: `/dashboard/menu`,
				label: 'Menu',
				icon: 'app_menu',
				permission: Permissions.ReadAllSegment,
				inclusive: false
			},
			{
				// path: `/dashboard/${projectId}/categories`,
				path: `/dashboard/categories`,
				label: 'Categories',
				icon: 'app_category',
				permission: Permissions.ReadAllSegment,
				inclusive: false
			},
			{
				// path: `/dashboard/${projectId}/dishes`,
				path: `/dashboard/dishes`,
				label: 'Dishes',
				icon: 'app_dish',
				permission: Permissions.ReadAllSegment,
				inclusive: false
			},
			{
				// path: `/dashboard/${projectId}/profile`,
				path: `/dashboard/profile`,
				label: 'Profile',
				icon: 'app_profile',
				permission: Permissions.ReadAllSegment,
				inclusive: false
			},
			{
				// path: `/dashboard/${projectId}/appearance`,
				path: `/dashboard/appearance`,
				label: 'Appearance',
				icon: 'app_appearance',
				permission: Permissions.ReadAllSegment,
				inclusive: false
			},
			{
				// path: `/dashboard/${projectId}/billing`,
				path: `/dashboard/billing`,
				label: 'Billing',
				icon: 'app_credit-card',
				permission: Permissions.ReadAllSegment,
				inclusive: false
			},
			// {
			// 	// path: `/dashboard/${projectId}/tags`,
			// 	path: `/dashboard/tags`,
			// 	label: 'Tags',
			// 	icon: 'app_tags',
			// 	permission: Permissions.ReadAllSegment,
			// 	inclusive: false
			// },
			// {
			// 	// path: `/dashboard/${projectId}/allergies`,
			// 	path: `/dashboard/allergies`,
			// 	label: 'Allergies',
			// 	icon: 'app_alert-circle',
			// 	permission: Permissions.ReadAllSegment,
			// 	inclusive: false
			// },
		];
	}
}
