import { Paging } from "./paging";
import { isNoEmptyArray, isNullOrUndefined, isValidString } from "@pineapplelab/util";

export class Table<T> {

	columns: Table.IColumn[] = [];
	paging: Paging<T>;
	initialized = false;
	showDeletedRows = false;
	hidePageSize?: boolean = false;
	hidePaginator?: boolean = false;

	constructor(object: Table.ITable<T>) {

		if (isNullOrUndefined(object.paging.loadData) || typeof object.paging.loadData !== 'function') {
			throw new Error("You must initialize paging class loadData method!");
		}

		object.columns = object.columns.map(column => isValidString(column) ? { field: column, label: column } : column) as Table.IColumn[];

		this.paging = object.paging;
		this.columns = object.columns as Table.IColumn[];
		this.hidePageSize = object.hidePageSize;
		this.hidePaginator = object.hidePaginator;

	}
}

export namespace Table {
	export interface ITable<T> {
		paging: Paging<T>;
		columns: (Table.IColumn | string)[];
		hidePageSize?: boolean;
		hidePaginator?: boolean;
	}

	export interface IColumn {
		field: string;
		label: string | any;
		template?: any;
		segments?: string[];
		cssClass?: string;
		orderOption?: IPagingOrderOption;
		__checked?: boolean;
	}

	type IPagingOrderOption = Paging['orderOptions'][number];

}
