import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { CoreModule } from '../core/core.module';

import { ToasterService } from '../services/toaster/toaster.service';

import { FooterComponent } from './footer/footer.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { HeaderComponent } from './header/header.component';
import { MatFormFieldComponent } from './mat-form-field/mat-form-field.component';
import { InformativeNotificationComponent } from './informative-notification/informative-notification.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CrudComponent } from './crud/crud.component';
import { MatTableComponent } from './mat-table/mat-table.component';
import { AlertComponent } from './alert/alert.component';
import { ChipsComponent } from './chips/chips.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PaginatorComponent } from './paginator/paginator.component';
import { AddDishesComponent } from './add-dishes/add-dishes.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { DragDropSortingComponent } from './drag-drop-sorting/drag-drop-sorting.component';
import { DishPreviewComponent } from './dish-preview/dish-preview.component';
import { CarouselImageComponent } from './carousel-image/carousel-image.component';
import { PagingSearchComponent } from './paging-search/paging-search.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { SelectFontComponent } from './select-font/select-font.component';
import { SelectColorComponent } from './select-color/select-color.component';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		PlanCardComponent,
		MatFormFieldComponent,
		InformativeNotificationComponent,
		SideBarComponent,
		SideMenuComponent,
		SpinnerComponent,
		CrudComponent,
		MatTableComponent,
		AlertComponent,
		ChipsComponent,
		PaginatorComponent,
		AddDishesComponent,
		AddCategoryComponent,
		DragDropSortingComponent,
		DishPreviewComponent,
		CarouselImageComponent,
		PagingSearchComponent,
		SuccessPaymentComponent,
		SelectFontComponent,
		SelectColorComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		RouterModule,
		CoreModule,
		PipesModule,
		ImageCropperModule
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		PlanCardComponent,
		MatFormFieldComponent,
		InformativeNotificationComponent,
		SideBarComponent,
		SideMenuComponent,
		SpinnerComponent,
		CrudComponent,
		MatTableComponent,
		AlertComponent,
		ChipsComponent,
		PaginatorComponent,
		AddDishesComponent,
		AddCategoryComponent,
		DragDropSortingComponent,
		DishPreviewComponent,
		CarouselImageComponent,
		PagingSearchComponent,
		SelectFontComponent,
		SelectColorComponent
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
})

export class SharedModule {
	constructor(
		private toasterService: ToasterService
	) {
		this.toasterService.setNotificationComponent(InformativeNotificationComponent);
	}
}
