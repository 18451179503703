import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { NavigationContentService } from 'src/app/services/navigation-content/navigation-content.service';
import { ScreenMediaService } from 'src/app/services/screen-media/screen-media.service';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss']
})

export class SideMenuComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() stepAdd: number = 0

	@ViewChild('sidenavContent') sidenavContent: MatSidenavContent;

	initializing = true;
	showTopBar: boolean = false;


	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public screenMediaService: ScreenMediaService,
		private navigationContentService: NavigationContentService,
	) { }

	ngAfterViewInit(): void {
		this.navigationContentService.setSidenavContent((this.sidenavContent as any).elementRef.nativeElement);
	}

	ngOnInit(): void {
		this.screenMediaService._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
		this.screenMediaService.mobileQuery.addEventListener('change', this.screenMediaService._mobileQueryListener);

		setTimeout(() => this.initializing = false, 500);
	}

	ngOnDestroy(): void {
		this.screenMediaService.mobileQuery.removeEventListener('change', this.screenMediaService._mobileQueryListener);
	}

}
