import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from '@pineapplelab/util';
import { Paging } from 'src/app/models/paging';

@Component({
	selector: 'app-paging-search',
	templateUrl: './paging-search.component.html',
	styleUrls: ['./paging-search.component.scss']
})

export class PagingSearchComponent implements OnInit {

	@Input() paging: Paging;
	@Output() pagingChanged = new EventEmitter<Paging>();
	@Input() wrap: boolean = false;
	@Input() invisibleBackground: boolean = false;
	@Input() hideFilter: boolean = false;
	@Input() placeholder: string = 'Search';

	OrderType = Paging.orderType;
	OrderDataType = Paging.dataType;
	filterByLabel: string = 'Filter by:' // textos.filterByLabel[environment.language];
	anyLabel: string = 'None' // textos.anyLabel[environment.language];
	searchPlaceholder: string;

	start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // last week
	end = new Date();

	constructor() { }

	ngOnInit() {
		this.setOrderByOption(this.paging.orderBy);
		if (this.paging.pagingSearchOptions.noOrderOptions) {
			this.searchPlaceholder = this.paging.pagingSearchOptions.placeholder;
		}
	}

	emitPagingChanged() {
		if (!isNullOrUndefined(this.paging.loadData) && typeof this.paging.loadData === 'function') {
			this.paging.loadData();
		}
		this.pagingChanged.emit(this.paging);
	}

	changeOrderType(orderType: Paging.orderType) {
		this.paging.orderType = orderType;
		this.emitPagingChanged();
	}

	changeSearch(search: string) {
		const temporalSearch = search;

		setTimeout(() => {
			if (temporalSearch !== this.paging.search) {
				return;
			}
			if (this.paging.orderDataType === null || this.paging.orderDataType === Paging.dataType.string) {
				this.paging.orderType = Paging.orderType.asc;
			}
			this.emitPagingChanged();
		}, 400);
	}

	changeOrderBy(value: string) {
		this.paging.orderBy = value !== undefined ? value : null;
		this.setOrderByOption(value);
		this.emitPagingChanged();
	}

	setOrderByOption(value: string) {
		const option = this.paging.orderOptions.find(o => o.field === value);
		if (isNullOrUndefined(option)) {
			this.paging.orderDataType = this.OrderDataType.string;
			return;
		}
		this.paging.orderDataType = option.dataType;
		this.searchPlaceholder = option.placeholder;
		if (this.paging.orderDataType !== Paging.dataType.timestamp) {
			this.paging.search = '';
		} else {
			this.setSearchDate();
		}
	}

	setSearchDate() {
		const data = { start: this.start, end: this.end };
		this.paging.search = JSON.stringify(data);
		this.emitPagingChanged();
	}
}
