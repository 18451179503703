import { FBase } from "./firebase.base";
import { isObject } from "@pineapplelab/util";
import { Base } from "../base";

export class UserRole extends FBase {

	roles: UserRole.ITakenRole[] = [];
	rolesId: string[] = [];

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}
}

export namespace UserRole {
	export const PATH = 'userRoles';

	export interface ITakenRole extends Base.IModelRelationship { };
}
