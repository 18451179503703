import { Injectable } from '@angular/core';
import { isNotEmptyString, isNullOrUndefined } from '@pineapplelab/util';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { BrowserStorage } from '../../models/browserStorage';

@Injectable({
	providedIn: 'root'
})

export class CrudService {

	constructor() { }

	setOverwriteFieldsFor10SecondsTillReload(id: string, object: { [key: string]: any }) {
		if (!isNullOrUndefined(object?.['id'])) {
			throw new Error('The id field is not allowed to be overwritten');
		}
		const objectsById = this.getValidOverwrittenFields();
		if (isNullOrUndefined(objectsById[id])) {
			objectsById[id] = {} as any;
		}
		objectsById[id].fromDate = moment().valueOf();
		objectsById[id].object = object;
		BrowserStorage.set('overwriteFieldsFor10SecondsTillReload', objectsById);
	}

	overwriteFieldsIfHaveTo(doc: { [key: string]: any, id: string }) {
		if (!isNotEmptyString(doc?.id)) {
			return;
		}
		const fieldsByDate = this.getValidOverwrittenFields();
		if (isNullOrUndefined(fieldsByDate[doc.id])) {
			return;
		}
		const fieldsToOverwrite = fieldsByDate[doc.id].object;
		Object.assign(doc, fieldsToOverwrite);
	}

	private getValidOverwrittenFields() {
		const objectById: IOverwrittenFields = BrowserStorage.getParsed('overwriteFieldsFor10SecondsTillReload') || {};
		const now = moment();
		Object.keys(objectById).map((id) => {
			if (moment(objectById[id].fromDate).isBefore(now.subtract(10, 's'))) {
				delete objectById[id];
			}
		});
		return objectById;
	}

}

interface IOverwrittenFields {
	[docId: string]: { fromDate: number, object: { [key: string]: any } };
}
