export const environment = {
	production: false,
	URL: "https://checkin-development.web.app/",
	API_URL: 'https://us-central1-checkin-development.cloudfunctions.net/api',
	projectId: 'LErWk50l3ga929tDqWNq',
	firebase: {
		projectId: 'checkin-development',
		appId: '1:902075999501:web:375550e65a0c5c448e7c44',
		storageBucket: 'checkin-development.appspot.com',
		locationId: 'us-central',
		apiKey: 'AIzaSyDty_53WheLMOX5FRrxR9v5jCq8X-5HFtg',
		authDomain: 'checkin-development.firebaseapp.com',
	},
	tokenDurationHours: 72
};
