import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {

  constructor() { }

  public setTheme(themeName: string): void {
    document.body.classList.forEach(() => {
      document.body.classList.add(themeName);
    });
    localStorage.setItem('themeApp', themeName);
  }
}
