import { isNotEmptyString, isNullOrUndefined, isValidString } from '@pineapplelab/util';
import { CookieStorage } from './cookie';

enum BrowserStorageType {
	localStorage = 1,
	cookie = 2,
};
export class BrowserStorage {

	private static prefixName = 'app';
	static type: BrowserStorageType = BrowserStorageType.localStorage;
	static BrowserStorageType = BrowserStorageType;

	static get(name: string, saveIn = BrowserStorage.type) {
		if (saveIn === BrowserStorageType.cookie) {
			return CookieStorage.get(name);
		}
		if (!isNotEmptyString(name)) {
			return null;
		}
		const value = localStorage.getItem(name);
		return isNotEmptyString(value) ? decodeURI(value) : null;
	}

	static getParsed(name: string, saveIn = BrowserStorage.type) {
		const result = BrowserStorage.get(name, saveIn);
		if (!isNotEmptyString(result)) {
			return null;
		}
		try {
			return JSON.parse(result);
		} catch (error) {
			return null;
		}
	}

	static set(name: string, value: any, sameSiteStrict = true, expireDays: number = null, saveIn = BrowserStorage.type) {
		if (!isValidString(value)) {
			value = isNullOrUndefined(value) ? null : JSON.stringify(value);
		}

		if (saveIn === BrowserStorageType.cookie) {
			return CookieStorage.set(name, value, sameSiteStrict, expireDays);
		}
		if (!isNotEmptyString(name)) {
			return;
		}
		if (isNullOrUndefined(value)) {
			return BrowserStorage.delete(name);
		}
		localStorage.setItem(name, encodeURI(value));
	}

	static delete(name: string, saveIn = BrowserStorage.type) {
		if (saveIn === BrowserStorageType.cookie) {
			return CookieStorage.delete(name);
		}
		if (!isNotEmptyString(name)) {
			return;
		}
		localStorage.removeItem(name);
	}

	private static overrideName(name: string) {
		return BrowserStorage.prefixName + name.charAt(0).toUpperCase() + name.slice(1);
	}

}
