<div fxLayout="column" fxLayoutAlign="space-between center" class="w-100">
	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="icon-title">
				<mat-icon svgIcon="app_title"></mat-icon>
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Font (dishes title, categories and categories buttons)</span>
				<b class="{{model?.configuration?.titleFont}}">{{model?.configuration?.titleFont}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="selectedFont(null)">Clear</span>

			<div class="bg-primary icon pointer" [matMenuTriggerFor]="menu">
				<mat-icon>edit a</mat-icon>

				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="selectedFont(fonts.caveat)">
						<span class="caveat">Caveat</span>
					</button>
					<button mat-menu-item (click)="selectedFont(fonts.anton)">
						<span class="anton">Anton</span>
					</button>
					<button mat-menu-item (click)="selectedFont(fonts.oswald)">
						<span class="oswald">Oswald</span>
					</button>
					<button mat-menu-item (click)="selectedFont(fonts.roboto)">
						<span class="roboto">Roboto</span>
					</button>
				</mat-menu>
			</div>
		</div>

	</div>
</div>
