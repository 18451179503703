import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class ToasterService {

	constructor(
		private _snackBar: MatSnackBar
	) { }

	private notificationComponent: any;

	setNotificationComponent(component: any) {
		this.notificationComponent = component;
	}

	show(text: string, action: string, seconds = 5) {
		return this._snackBar.openFromComponent(this.notificationComponent, {
			data: {
				message: text,
				type: action
			},
			duration: seconds * 1000,
			horizontalPosition: 'left',
			verticalPosition: 'bottom',
		})
	}

	showError(text: string, action = 'Error') {
		return this._snackBar.openFromComponent(this.notificationComponent, {
			horizontalPosition: 'left',
			verticalPosition: 'bottom',
			data: {
				message: text,
				type: action
			},
			duration: 5000
		})
	}

}
