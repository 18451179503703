import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isObject, isValidNumber, isValidString } from '@pineapplelab/util';
import moment from 'moment';

@Pipe({
	name: 'appDate'
})

export class AppDatePipe implements PipeTransform {

	constructor(
		private datePipe: DatePipe
	) { }

	transform(value: any, fromNowOrAngularFormat: boolean | string = false, numeric: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' = null, timezone: string = null) {
		let date = value;
		if (isObject(value) && value.seconds) {
			date = value.seconds * 1000;
		}
		if (!isValidNumber(date)) {
			return null;
		}
		if (numeric !== null) {
			if (!fromNowOrAngularFormat) {
				return date;
			}
			return moment(date).diff(moment(), numeric);
		}
		if (typeof fromNowOrAngularFormat === 'boolean' && fromNowOrAngularFormat === true) {
			return moment(date).fromNow();
		}
		return this.datePipe.transform(date, isValidString(fromNowOrAngularFormat) ? fromNowOrAngularFormat as string : 'mediumDate', timezone);
	}

}
