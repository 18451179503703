import firebase from 'firebase/compat/app';
import { Base } from "../base";
import { isObject } from "@pineapplelab/util";


export abstract class FBase<T = any> extends Base {

	private __createdAt = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
	private __updatedAt = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

	deletedAt: Object = null;
	createdBy: string = null;
	updatedBy: string = null;
	deletedBy: string = null;
	isDeleted: boolean = false;
	isHidden: boolean = false;
	id: string = null;

	set createdAt(timestamp: any) {
		this.__createdAt = timestamp;
	}

	get createdAt() {
		if (this.__createdAt instanceof firebase.firestore.FieldValue) {
			return null;
		}
		return this.__createdAt;
	}

	set updatedAt(timestamp: any) {
		this.__updatedAt = timestamp;
	}

	get updatedAt() {
		if (this.__updatedAt instanceof firebase.firestore.FieldValue) {
			return null;
		}
		return this.__updatedAt;
	}

	constructor(json?: { [key: string]: any }) {
		super();

		if (isObject(json)) {
			this.fromJson(json);
		}

	}

	override toJson(camelCase = false, onlyFields: string[] = null, onlyFieldsAreRemovedInTheJson = false) {
		const json = super.toJson(camelCase, onlyFields, onlyFieldsAreRemovedInTheJson);
		if (this.__createdAt instanceof firebase.firestore.FieldValue) {
			json.created_at = this.__createdAt;
		} else {
			json.updated_at = firebase.firestore.FieldValue.serverTimestamp();
		}
		if (!json.is_deleted) {
			json.is_deleted = false;
		}
		return json;
	}

	// setServerTimestamp(field: KeyOfByType<T, firebase.firestore.Timestamp>) {
	// 	(this as any)[field] = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
	// }

}
