import { Paging, PagingOrderType } from "@pineapplelab/paging";
import { isObject } from "@pineapplelab/util";
import { ObjectSubmodel } from "../objectSubmodel";
import { IsNotEmpty } from '@pineapplelab/validators';
import { SlugifyModel } from "../slugifyModel";
import { Tag } from "./tags";
import { Allergies } from "./allergies";

export class Dishes extends SlugifyModel {

	@IsNotEmpty({ label: "Title" })
	name: string = null;

	description: string = null;

	@IsNotEmpty({ label: "price" })
	price: number = null;

	currency: string = null;

	tags: ObjectSubmodel[] = [];

	allergies: ObjectSubmodel[] = [];

	coverFile: string = null;

	image: string[] = null;

	creator: ObjectSubmodel;

	__creatorFullName?: string = null;
	__file: File[] = [];
    __fileCover: File = null;
    __tag: Tag[] = [];
    __allergy: Allergies[] = [];
	__coverFileImage: boolean = null
	__dishMediaPreview: any = []

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}

	slugProperty(): string[] {
		return [this.name]
	}
}

export namespace Dishes {

	export const PATH = 'dishes';

	export const PAGING = new Paging<Dishes>(<Paging.IPaging>{
		collection: PATH,
		preconditions: [
			{ fieldPath: 'is_deleted', filterOp: '==', value: false }
		],
		orderOptions: [
			{ field: 'name', label: 'title', placeholder: 'Filter by name', dataType: Paging.dataType.string },
		],
		orderBy: 'created_at',
		orderType: PagingOrderType.desc
	});
}
