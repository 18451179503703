import firebase from 'firebase/compat/app';
import { Validator } from "@pineapplelab/validators";
import { camelToSnakeCase, isArray, isNoEmptyArray, isNotEmptyString, isNullOrUndefined, isObject, snakeToCamelCase } from "@pineapplelab/util";
import { AppErrorStateMatcher } from "./errorStateMatcher";

export abstract class Base implements Base.IBase {

	__errorsByField: { [field: string]: string[] } = {};
	__errorByField: { [field: string]: string } = {};
	__errorByFieldByDecorator: { [field: string]: { [decorator: string]: string } } = {};
	__matcherByField: { [field: string]: AppErrorStateMatcher } = {};
	__errors: string[] = [];
	__hasError: boolean = false;

	toJson(camelCase = false, onlyFields: string[] = [], onlyFieldsAreRemovedInTheJson = false) {
		if (camelCase) {
			return this.baseToJson(onlyFields, onlyFieldsAreRemovedInTheJson);
		}
		return this.toSnakeCaseJson(onlyFields, onlyFieldsAreRemovedInTheJson);
	}

	fromJson(json: { [key: string]: any }) {
		this.fromJsonToBase(json);
		return this;
	}

	private baseToJson(onlyFields: string[] = [], onlyFieldsAreRemovedInTheJson = false) {
		const trueValues: any[] = [];
		const text = JSON.stringify(this, ((key, value) => {
			if (this.isSensitiveValue(value)) {
				return `$__sensitiveValue${trueValues.push(value) - 1}`;
			}
			return value;
		}));
		const json = JSON.parse(text, (key, value) => {
			if (isNotEmptyString(value) && value.startsWith('$__sensitiveValue')) {
				return trueValues[parseInt(value.slice(17), 10)];
			}
			return value;
		});
		this.removeAllPrivetProperties(json);

		if (!isNoEmptyArray(onlyFields)) {
			return json;
		}
		Object.keys(json).map(key => {
			const isIncluded = onlyFields.includes(key);
			if ((!isIncluded && !onlyFieldsAreRemovedInTheJson) || (isIncluded && onlyFieldsAreRemovedInTheJson)) {
				delete json[key];
			}
		});
		return json;
	}

	private toSnakeCaseJson(onlyFields: string[] = [], onlyFieldsAreRemovedInTheJson = false) {
		const json = this.baseToJson(onlyFields, onlyFieldsAreRemovedInTheJson);
		this.toSnakeCaseAllSubObjects(json);
		return json;
	}

	private fromJsonToBase(json: { [key: string]: any }) {
		this.toCamelCaseAllSubObjects(json);
		Object.assign(this, json);
	}

	private toCamelCaseAllSubObjects(object: any) {
		this.modifyEveryKeyInAObject(object, snakeToCamelCase);
	}

	private toSnakeCaseAllSubObjects(object: any) {
		this.modifyEveryKeyInAObject(object, camelToSnakeCase);
	}

	private modifyEveryKeyInAObject(object: any, keyModifier: (key: string) => string) {
		this.doItForEveryKeyInAObject(object, (key, _object) => {
			const snakeKey = keyModifier(key);
			if (snakeKey === key) {
				return;
			}
			_object[snakeKey] = _object[key];
			delete _object[key];
		});
	}

	private removeAllPrivetProperties(object: any) {
		this.doItForEveryKeyInAObject(object, (key, _object) => {
			if (key.startsWith('__')) {
				delete _object[key];
			}
		});
	}

	private doItForEveryKeyInAObject(object: any, it: (key: string, object: any) => void, deepLevel = 0) {
		deepLevel++;
		if (deepLevel > DEEP_LEVEL_MAX || !isObject(object) || this.isSensitiveValue(object)) {
			return;
		}
		if (isArray(object as any[])) {
			return object.map((item: any) => this.doItForEveryKeyInAObject(item, it, deepLevel));
		}
		Object.keys(object).map(key => {
			if (isObject(object[key])) {
				this.doItForEveryKeyInAObject(object[key], it, deepLevel);
			}
			it(key, object);
		});
	}

	private isSensitiveValue(object: any) {
		if (isNullOrUndefined(object)) {
			return false;
		}
        const types = [firebase.firestore.DocumentReference, firebase.firestore.FieldValue, AppErrorStateMatcher];
		return types.some(type => object instanceof type);
	}

	isValid(witheList: string[] = []) {
		const result = Validator.validate(this);
		if (!isNoEmptyArray(witheList)) {
			if (!isNoEmptyArray(witheList)) {
				return this.setErrorData(result);
			}
		}
		witheList.map(field => {
			delete result.fieldError[field];
			delete result.fieldErrors[field];
			delete result.fieldDecoratorError[field];
		});
		result.errors = [];
		Object.keys(result.fieldErrors).map(field => result.errors.push(...result.fieldErrors[field]));
		result.valid = result.errors.length === 0;
		return this.setErrorData(result);
	}

	private setErrorData(result: any) {
		this.__hasError = !result.valid;
		this.__errorByField = result.fieldError;
		this.__errorsByField = result.fieldErrors;
		this.__errors = result.errors;
		this.__errorByFieldByDecorator = result.fieldDecoratorError;
		Object.keys(this.__errorByField).map(field => {
			if (isNullOrUndefined(this.__matcherByField[field])) {
				this.__matcherByField[field] = new AppErrorStateMatcher(this, field);
			}
		});
		return !this.__hasError;
	}

}

/** The `export namespace Base` block is defining a namespace called `Base` within the module. */
export namespace Base {
	export interface IModelRelationship {
		id: string;
		reference: firebase.firestore.DocumentReference;
		name: string;
	};

	export interface IBase {
		__matcherByField: { [field: string]: AppErrorStateMatcher };
		__errorByField: { [field: string]: string };
		isValid: () => boolean;
		[key: string]: any;
	};

}

const DEEP_LEVEL_MAX = 10;
