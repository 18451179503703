import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';
import { SuccessPaymentComponent } from './shared/success-payment/success-payment.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
	},
	{
		path: 'auth',
		// runGuardsAndResolvers: 'always',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
	},
	{
		path: 'dashboard',
		canActivate: [authGuard],
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
	},
	{
		path: 'menu',
		loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule),
	},
	{
		path: 'account',
		loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
	},
	{
		path: 'success-payment',
		component: SuccessPaymentComponent,
		canActivate: [authGuard],
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
