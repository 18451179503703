import { Injectable } from '@angular/core';
import { camelToSnakeCase, isNoEmptyArray, isNotEmptyString, isNullOrUndefined } from '@pineapplelab/util';
import { Permissions } from '../../enums/permissions.enum';
import { Project } from '../../models/firebase/project';
import { Role } from '../../models/firebase/role';
import { User } from '../../models/firebase/user';
import { UserRole } from '../../models/firebase/userRole';
import { DbService } from '../db/db.service';
import { Preconditions } from 'src/app/interfaces/i-preconditions';

@Injectable({
	providedIn: 'root'
})

export class RoleService {

	constructor(
		private db: DbService
	) { }

	async save(model: Role, projectId: string) {
		if (isNullOrUndefined(model.id)) {
			model.id = this.db.createId();
		}

		const json = model.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Role.PATH).doc(model.id).set(json, { merge: true });
		return json;
	}

	remove(projectId: string, modelId: string) {
		const json = { is_deleted: true, deleted_at: this.db.serverTimeStamp() };
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(Role.PATH).doc(modelId).set(json, { merge: true });
	}

	docReference(projectId: string, modelId: string) {
		const path = this.db.root.collection(Project.PATH).doc(projectId).collection(Role.PATH).doc(modelId).ref.path;
		return this.db.firestore.doc(path);
	}

	async saveUserRoles(model: UserRole, projectId: string) {
		if (isNullOrUndefined(model.id)) {
			throw new Error("UserRoleId must be equal to the right user id");
		}

		model.roles.forEach(role => role.reference = this.docReference(projectId, role?.id));
		model.rolesId = model.roles.map(role => role?.id);

		const json = model.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(UserRole.PATH).doc(model.id).set(json, { merge: true });
		return json;
	}

	getUserRole(projectId: string, modelId: string) {
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(UserRole.PATH).doc(modelId).get();
	}

	getRolesByPermission(projectId: string, permission: Permissions) {
		return this.db.root.collection(Project.PATH).doc(projectId).collection(Role.PATH).ref.where('permissions', 'array-contains', permission).get();
	}

	async getRoles(projectId: string, preconditions: Preconditions[] = []) {
		let baseRolesQuery: any = this.db.firestore.collection(Project.PATH).doc(projectId).collection(Role.PATH);

		if (isNoEmptyArray(preconditions)) {
			preconditions.forEach(precondition => {
				baseRolesQuery = baseRolesQuery.where(precondition?.field, precondition?.filterOp, precondition?.value);
			});
		}

		const response = await baseRolesQuery.get();

		const rolesData = response.docs.map((role: { exists: any; data: () => { (): any; new(): any; id: any; name: any; }; }) => {
			if (!role.exists) {
				return null;
			}

			return { id: role?.data()?.id, name: role?.data()?.name };
		});

		return rolesData.filter((item: any) => item);
	}

	async getRolesDictionary(projectId: string): Promise<{ [key: string]: string }> {
		const roles = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Role.PATH).get();
		let idToName: { [key: string]: string } = {};
		let nameToId: { [key: string]: string } = {};

		for (const role of roles.docs) {
			if (!role.exists) {
				continue;
			}
			const roleData = new Role(role.data());
			idToName[roleData.id] = roleData.name;
			nameToId[roleData.name] = roleData.id;
		}

		return { ...idToName, ...nameToId };
	}

	async getRolesByUserId(projectId: string, userId: string) {
		const userDoc = await this.db.root.collection(Project.PATH).doc(projectId).collection(UserRole.PATH).doc(userId).get().toPromise();
		if (!userDoc.exists || !isNoEmptyArray(userDoc.data()['roles'])) {
			return [];
		}
		const roles: Role[] = [];
		const promises = userDoc.data()['roles'].map(async (r: any) => {
			if (isNullOrUndefined(r?.reference)) {
				return;
			}
			const role = await r?.reference?.get();
			if (isNullOrUndefined(role) || !role?.exists) {
				return;
			}
			roles.push(new Role(role.data()));
		});

		await Promise.all(promises);

		return roles;
	}

	async getUsersByRole(projectId: string, roleId?: string) {
		if (!isNotEmptyString(roleId)) {
			const users = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).get();
			return users.docs.map(user => new User(user.data())).filter(user => user?.id);
		}
		const roleDocument = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Role.PATH).doc(roleId).get();
		if (!roleDocument?.exists || roleDocument?.data()?.['is_deleted']) {
			throw new Error('Role was not found');
		}

		const roleData = new Role(roleDocument.data());
		if (isNullOrUndefined(roleData?.id) || isNullOrUndefined(roleData?.name)) {
			throw new Error('ID or Name field was not found')
		}
		const userData = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(UserRole.PATH).where('roles_id', 'array-contains', roleId).get();
		if (userData.empty) {
			throw new Error('No users with this role');
		}

		const usersId = userData.docs.map(ids => {
			if (!ids.exists || !ids?.data()?.['id']) {
				return;
			}
			return ids?.data()?.['id'];
		}).filter(item => item);
		const promises = usersId.map(async userId => {
			return this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).get();
		})

		const usersDocument = await Promise.all(promises);
		const users = usersDocument.map(user => new User(user?.data())).filter(user => user?.id);
		return users;
	}

	async scriptUpdateDefaultRoleToAllUsers(projectId: string) {
		const roles = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Role.PATH).where('is_default', '==', true).get();
		if (roles.docs.length === 0) {
			return;
		}
		const trueUsers = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).get();

		await this.promiseByBatch(trueUsers.docs, async trueUser => {

			const userRole = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(UserRole.PATH).doc(trueUser.id).get();

			const user = new UserRole(userRole.data());
			let rolesToAdd = roles.docs;

			if (isNullOrUndefined(user.roles)) {
				user.roles = [];
			}
			if (isNoEmptyArray(user.roles)) {
				rolesToAdd = roles.docs.filter(role => user.roles.findIndex(r => r.id === role.id) === -1);
			}

			if (!isNoEmptyArray(rolesToAdd)) {
				return;
			}

			rolesToAdd.map(role => {
				user.roles.push({
					name: role.data()['name'],
					reference: role.ref,
					id: role.id
				});
			});

			await userRole.ref.set({ roles: user.roles }, { merge: true });
		});
	}

	private async promiseByBatch<T = any>(array: T[], handler: (item: T) => Promise<T | void>, size = 20, results: (T | void)[] = []): Promise<(T | void)[]> {
		if (!isNoEmptyArray(array) || size === 0) {
			return results;
		}
		const todo = array.splice(0, size);
		const promises = todo.map(handler);
		const batchResults = await Promise.all(promises);
		results = results.concat(batchResults);
		return this.promiseByBatch(array, handler, size, results);
	}

}
