import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
	selector: 'app-dish-preview',
	templateUrl: './dish-preview.component.html',
	styleUrls: ['./dish-preview.component.scss']
})

export class DishPreviewComponent implements OnInit {

	currentImage: string;
	currentIndex: number = 0;
	isImage: boolean = false
	dummyImage: string = 'assets/image.jpg';

	constructor(
		private spinnerService: SpinnerService,
		@Inject(MAT_DIALOG_DATA) public data: { dishData: any, configuration: any, isMobile: boolean, isIphone: boolean}
	) { }

	async ngOnInit(): Promise<void> {
	}

	onError(event: Event) {
		const imgElement = event.target as HTMLImageElement;
		imgElement.src = this.dummyImage;
	}
}
