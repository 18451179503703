import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	icons = [
		{ name: 'upload', url: '/assets/icons/icon-upload-cloud.svg' },
		{ name: 'menu', url: '/assets/icons/menu.svg' },
		{ name: 'dish', url: '/assets/icons/dish.svg' },
		{ name: 'category', url: '/assets/icons/category.svg' },
		{ name: 'setting', url: '/assets/icons/setting.svg' },
		{ name: 'instagram', url: '/assets/icons/insta.svg' },
		{ name: 'facebook', url: '/assets/icons/facebook.svg' },
		{ name: 'drop', url: '/assets/icons/drop.svg' },
		{ name: 'title', url: '/assets/icons/title.svg' },
		{ name: 'font', url: '/assets/icons/font.svg' },

		{ name: 'dairy', url: '/assets/icons/dairy.svg' },
		{ name: 'peanut', url: '/assets/icons/peanut.svg' },
		{ name: 'seafood', url: '/assets/icons/seafood.svg' },

		{ name: 'dairy-free', url: '/assets/icons/dairy-free.svg' },
		{ name: 'gluten-free', url: '/assets/icons/gluten-free.svg' },
		{ name: 'kosher', url: '/assets/icons/kosher.svg' },
		{ name: 'sugar-free', url: '/assets/icons/sugar-free.svg' },
		{ name: 'sugar', url: '/assets/icons/sugar.svg' },
		{ name: 'seafood-free', url: '/assets/icons/seafood-free.svg' },
		{ name: 'peanut-free', url: '/assets/icons/peanut-free.svg' },
		{ name: 'organic', url: '/assets/icons/organic.svg' },
		{ name: 'vegetarian', url: '/assets/icons/vegetarian.svg' },
		{ name: 'vegan', url: '/assets/icons/vegan.svg' },
		{ name: 'mildly-spicy', url: '/assets/icons/mildly-spicy.svg' },
		{ name: 'moderately-spicy', url: '/assets/icons/moderately-spicy.svg' },
		{ name: 'very-spicy', url: '/assets/icons/very-spicy.svg' },

		{ name: 'arrow-up', url: '/assets/icons/arrow-up.svg' },
		{ name: 'credit-card', url: '/assets/icons/credit-card.svg' },
		{ name: 'profile', url: '/assets/icons/user.svg' },
		{ name: 'tags', url: '/assets/icons/tags.svg' },
		{ name: 'alert-circle', url: '/assets/icons/alert-circle.svg' },
		{ name: 'appearance', url: '/assets/icons/wand.svg' },
	];

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) { }

	initializeAppIcons() {
		this.icons.map((icon) => {
			this.matIconRegistry.addSvgIcon(
				`app_${icon.name}`,
				this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
			);
		});
	}
}
