import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-informative-notification',
  templateUrl: './informative-notification.component.html',
  styleUrls: ['./informative-notification.component.scss']
})
export class InformativeNotificationComponent implements OnInit {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: any
    ) { }

    ngOnInit(): void {
    }

}
