
import { Paging as PagingClass } from "@pineapplelab/paging";
import { isNullOrUndefined } from "@pineapplelab/util";

export class Paging<T = any> extends PagingClass<T> {

	override orderOptions: Array<{ label: string, field: string, placeholder: string, dataType: PagingClass.dataType }> = [
		{ field: 'name', label: 'name', placeholder: 'Filter by name', dataType: Paging.dataType.string }
	];

	constructor(paging?: Partial<Paging.IPaging<T>>) {
		super(paging);
		if (!isNullOrUndefined(paging?.orderOptions)) {
			this.orderOptions = paging.orderOptions;
		}
	}
}

export namespace Paging {
	export interface IPagingResponse<T = any> extends PagingClass.IPagingResponse<T> { }
	export interface IPaging<T = any> extends PagingClass.IPaging<T> { }
	export const dataType = PagingClass.dataType;
	export type dataType = PagingClass.dataType;
	export const pagingAction = PagingClass.pagingAction;
	export type pagingAction = PagingClass.pagingAction;
	export const orderType = PagingClass.orderType;
	export type orderType = PagingClass.orderType;
}
