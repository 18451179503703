<mat-form-field focused="false" [appearance]="appearance">

	<mat-label *ngIf="label">{{label}}</mat-label>

	<input class=" border-accent" *ngIf="type !== 'textarea' && type !== 'number'" matInput
		[(ngModel)]="model[property]" [min]="min" [max]="max"
		[type]="type === 'password' && show ? 'text' : type" [errorStateMatcher]="model.__matcherByField[property]"
		name="{{inputName ? inputName : property}}" (change)="validateOnInput ? model.isValid() : ''"
		[disabled]="disabled" placeholder="{{placeholder}}">

	<input class=" border-accent" *ngIf="type === 'number'" matInput [(ngModel)]="model[property]"
		type="number" [min]="min" [errorStateMatcher]="model.__matcherByField[property]"
		name="{{inputName ? inputName : property}}" (change)="model.isValid()" [disabled]="disabled"
		placeholder="{{placeholder}}">

	<span *ngIf="time != null" matTextSuffix>{{ time }}</span>

	<textarea class=" border-accent" matInput *ngIf="type === 'textarea'" [(ngModel)]="model[property]"
		name="{{property}}" [disabled]="disabled" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
		[cdkAutosizeMinRows]="minRows" [cdkAutosizeMaxRows]="maxRows"
		[errorStateMatcher]="model.__matcherByField[property]" (change)="model.isValid()"
		placeholder="{{placeholder}}"></textarea>

	<button tabindex="-1" *ngIf="model[property] && !hideCleanButton && !disabled" matSuffix mat-icon-button
		aria-label="Clear" (click)="cleanProperty()" type="button">
		<mat-icon>close</mat-icon>
	</button>

	<button tabindex="-1" *ngIf="type === 'password' && showSeePassword" class="tittle hidden" type="button"
		(click)="show = !show" matSuffix mat-icon-button aria-label="Clear">
		<mat-icon *ngIf="show" class="input-labels">visibility_outlined</mat-icon>
		<mat-icon *ngIf="!show" class="input-labels">visibility_off_outlined</mat-icon>
	</button>

	<button tabindex="-1" *ngIf="type === 'search'" class="tittle" type="button" matPrefix mat-icon-button>
		<mat-icon class="input-labels">search</mat-icon>
	</button>

	<mat-error *ngIf="model.__errorByField[property]">{{model.__errorByField[property]}}</mat-error>
	<mat-hint *ngIf="hint">{{hint}}</mat-hint>

</mat-form-field>
