import { isNotEmptyString, isNullOrUndefined, isValidNumber } from "@pineapplelab/util";

export class CookieStorage {

	static get(name: string) {
		if (!isNotEmptyString(name)) {
			return null;
		}
		const cookie = document.cookie.split('; ').find(c => c.startsWith(`${name}=`));
		if (isNullOrUndefined(cookie)) {
			return null;
		}
		const value = decodeURI(cookie.substr(name.length + 1));
		return isNotEmptyString(value) ? value : null;
	}

	static set(name: string, value: string, sameSiteStrict = true, expireDays: number = null) {
		if (!isNotEmptyString(name)) {
			return;
		}
		if (isNullOrUndefined(value)) {
			return CookieStorage.delete(name);
		}
		let newCookieValue = `${name}=${encodeURI(value)};path=/${sameSiteStrict ? ';SameSite=Strict;Secure' : ';SameSite=None;Secure'}`;
		if (isValidNumber(expireDays)) {
			const expireDate = new Date();
			expireDate.setDate(expireDate.getDate() + expireDays);
			newCookieValue = `${newCookieValue};expires=${expireDate.toUTCString()}`;
		}
		document.cookie = newCookieValue;
	}

	static delete(name: string) {
		if (!isNotEmptyString(name)) {
			return;
		}
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
	}

}
