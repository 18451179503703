import firebase from "firebase/compat/app";
import { isNullOrUndefined, isObject } from "@pineapplelab/util";
import { IsEmail, IsAlphanumeric, Custom, MinLength } from "@pineapplelab/validators";
import { Paging } from "../paging";
import { SlugifyModel } from "../slugifyModel";

export class User extends SlugifyModel {

	override id: string = null;
	firstName: string = null;
	lastName: string = null;
	email: string = null;
	avatar: string = null;
	firebaseId: string = null;
	city: string = null;
	state: string = null;
	country: string = null;
	address: string = null;
	tiktok: string = null;
	youtube: string = null;
	facebook: string = null;
	instagram: string = null;
	lastLogin: firebase.firestore.Timestamp = null;
	configuration: configuration = null;
	restaurantName: string = null
	imageBanner: string[] = [];
	showHideBanner: boolean = null;
	maxMenuCount: number = 1;
	menuCount: number = 0;
	hasActiveSubscription: boolean = false

	/*Not Mapped*/
	__fileImage: File;
	__fileBanner: File[] = [];
	__status: number;
	__userRoles?: string[] = [];

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}

	slugProperty(): string[] {
		return [this.restaurantName]
	}

	get getFullName(): string {
		var firstName = this.firstName?.charAt(0)?.toUpperCase() + this.firstName?.slice(1);
		var lastName = this.lastName?.charAt(0)?.toUpperCase() + this.lastName?.slice(1);

		return `${firstName || ''} ${lastName || ''}`;
	}

	get getFullNameInitials(): string {
		const initials = this.firstName?.charAt(0)?.toUpperCase() + this.lastName?.charAt(0)?.toUpperCase();
		return initials;
	}

	get getNameAndInitialLastName(): string {
		const name = this.firstName?.charAt(0)?.toUpperCase() + this.firstName?.slice(1);
		const lastNameInitial = this.lastName?.charAt(0)?.toUpperCase();
		return `${name || ''}  ${lastNameInitial || ''}`
	}
}

export interface configuration {
	titleFont?: string,
	backgroundColor?: string,
	primaryColor?: string,
	accentColor?: string,
	borderColor?: string,
	titleColor?: string,
	descriptionColor?: string,
	priceColor?: string,
	restaurantTitleColor?: string,
}

export namespace User {
	export const PATH = 'users';

	/** The code is creating an instance of the `Paging` class and assigning it to the constant `PAGING`. */
	export const PAGING = new Paging<User>(<Paging.IPaging>{
		collection: PATH,
		orderOptions: [
			{ field: 'email', label: 'Email', placeholder: 'Filter by email', dataType: Paging.dataType.string },
			{ field: 'first_name', label: 'First Name', placeholder: 'Filter by first name', dataType: Paging.dataType.string },
			{ field: 'last_name', label: 'Last Name', placeholder: 'Filter by last name', dataType: Paging.dataType.string }
		],
		orderBy: 'email'
	});
}
