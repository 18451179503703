<!-- <mat-list cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="!expansion; else expansion"> -->
<mat-list cdkDropList (cdkDropListDropped)="drop($event)">
	<div *ngFor="let item of list; index as i" cdkDrag fxLayout="column" fxLayoutAlign="start stretch"
		class="item-container border-primary">

		<div fxLayout="row" class="title" fxLayoutAlign="space-between center">
			<mat-icon class="pointer" *ngIf="showRemove" (click)="$event.stopPropagation(); remove(item)"
				matTooltip="Remove item">close</mat-icon>
			<mat-icon *ngIf="!template" color="primary">drag_indicator</mat-icon>
			<mat-icon *ngIf="template" cdkDragHandle color="primary">drag_indicator</mat-icon>
		</div>

		<mat-divider></mat-divider>

		<div fxLayout="row" fxLayoutAlign="space-between center">

			<span *ngIf="!template">
				{{ item[field] }}
			</span>

			<ng-container *ngIf="template !== undefined && template !== null" [ngTemplateOutlet]="template"
				[ngTemplateOutletContext]="{ item: item, index: i, extras: contextValue }">
			</ng-container>

		</div>

		<mat-divider></mat-divider>

	</div>
	<p *ngIf="list.length === 0">There are not items for sort.</p>
</mat-list>

<!-- <ng-template #expansion>
	<mat-list cdkDropList (cdkDropListDropped)="drop($event)">
		<div *ngFor="let item of list; index as i" cdkDrag fxLayout="column" fxLayoutAlign="start stretch"
			class="itemContainer border-accent-darker">
			<mat-expansion-panel #panel hideToggle (opened)="item.__expanded = true" (closed)="item.__expanded = false"
				[expanded]="isExpanded[i] ? isExpanded[i] : item.__expanded">
				<mat-expansion-panel-header>
					<mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
						class="text-three-dots" [ngStyle]="{'max-width.px': width}">
						<mat-icon *ngIf="!template">drag_indicator</mat-icon>
						<mat-icon *ngIf="template" cdkDragHandle>drag_indicator</mat-icon>
						<ng-container *ngIf="!titleByObject">
							<mat-label *ngIf="showIndexedTitle && !ownTitle?.active && !dynamicTitle ">{{ indexedTitle ?
								indexedTitle + (i +
								1)
								: (title ||
								item?.name) }}</mat-label>
							<span *ngIf="ownTitle.modalities">
								{{ modalitiesDictionary[item?.type] }}
							</span>
							<span *ngIf="ownTitle.activities">
								{{ activitiesDictionary[item?.activityType] }}
							</span>
							<ng-container *ngIf="ownTitle?.articles">
								<ng-container [ngSwitch]="item?.type">
									<span *ngSwitchCase="blockType.Title">
										{{ item?.content ? item.content +' - Title' : 'Title' }}
									</span>
									<ng-container *ngSwitchCase="blockType.Text">
										<span #container class="truncate">
											<span *ngIf="item?.content">
												<span>
													{{ removeHtml(item?.content) ? removeHtml(item?.content)+' - Text':
													'Text'}}
												</span>
											</span>
											<span *ngIf="!item?.content">Text</span>
										</span>
										<span *ngIf="container.scrollWidth > 535">- Text</span>
									</ng-container>
									<span *ngSwitchCase="blockType.Image">
										<span>
											{{ item?.multimediaName ? item?.multimediaName +' - Image' : 'Image'}}
										</span>
									</span>
									<span *ngSwitchCase="blockType.Video">
										<span>
											{{item?.multimediaName ? item?.multimediaName + ' - Video' : 'Video'}}
										</span>
									</span>
								</ng-container>
							</ng-container>
							<span *ngIf="ownTitle.lessons">
								<span *ngIf="!item?.lesson">{{
									item?.zoomLesson?.title ? item?.zoomLesson?.title + ' - Zoom lesson' : 'Zoom lesson'
									}}</span>
								<span *ngIf="item?.lesson">{{ item?.lesson?.name ? item?.lesson?.name + ' - Lesson' :
									'Lesson' }}</span>
							</span>
							<mat-label *ngIf="dynamicTitle"> {{item.type}}</mat-label>
						</ng-container>
						<ng-container *ngIf="titleByObject">
							<span>{{ titleByObject.object[item[titleByObject.field]] }}</span>
						</ng-container>
					</mat-panel-title>
					<mat-panel-description fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
						<ng-container
							*ngIf="editorialMode?.active && item.legoType === titleByObject?.type ? titleByObject.type : null">
							<mat-button-toggle-group name="editorialMode">
								<mat-button-toggle value="manual" [checked]="item?.threeSlotCarouselMode === 'manual'"
									(click)="$event.stopPropagation(); emitThreeSlotCarouselMode('manual', i)" #manual
									[ngClass]="{'bg-primary-darker': manual.checked}">MANUAL</mat-button-toggle>
								<mat-button-toggle value="auto" [checked]="item?.threeSlotCarouselMode === 'auto'"
									(click)="$event.stopPropagation(); emitThreeSlotCarouselMode('auto', i)" #auto
									[ngClass]="{'bg-primary-darker': auto.checked}">AUTO</mat-button-toggle>
							</mat-button-toggle-group>
						</ng-container>
						<ng-container *ngIf="editorialMode?.active">
							<div *ngIf="editorialMode?.clubActivityFeedState[item?.id] === true"
								class="container bg-label-lighter" fxLayout="column">
								<p class="m-0"><b>SUCCESS</b></p>
							</div>
							<div *ngIf="editorialMode?.clubActivityFeedState[item?.id] === false"
								class="container bg-label-darker" fxLayout="column">
								<p class="m-0 text-primary-contrast">ERROR</p>
							</div>
						</ng-container>
						<mat-icon class="pointer " *ngIf="!item.__expanded">expand_more
						</mat-icon>
						<mat-icon class="pointer " *ngIf="item.__expanded">expand_less
						</mat-icon>
						<mat-icon class="pointer" *ngIf="showRemove" (click)="$event.stopPropagation(); remove(item, i)"
							matTooltip="Remove item">close</mat-icon>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-divider></mat-divider>
				<div class="expansion-body">
					<ng-container *ngIf="template !== undefined && template !== null && panel.expanded"
						[ngTemplateOutlet]="template"
						[ngTemplateOutletContext]="{ item: item, index: i, extras: contextValue }">
					</ng-container>
				</div>
			</mat-expansion-panel>
			<p *ngIf="list.length === 0">There are not items for sort.</p>
		</div>
	</mat-list>
</ng-template> -->
