import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Paging } from '@pineapplelab/paging';
import { isNullOrUndefined } from '@pineapplelab/util';

@Component({
	selector: 'app-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss']
})

export class PaginatorComponent implements OnInit, OnChanges {

	@Input() paging: Paging;
	@Input() hidePageSize: boolean;
	@Input() marginRight: boolean = true;
	@Input() pagesMount: number;

	@Output() pagingChanged = new EventEmitter<Paging>();

	pages: number[];
	startPage: number;
	endPage: number;
	currentPage = 1;
	itemsPerPageLabel: string = 'Elements by page.'
	nextPageLabel: string = 'Next page'
	previousPageLabel: string = 'Previous page'

	constructor() { }

	ngOnChanges(changes: SimpleChanges): void {

		if (isNullOrUndefined(changes['pagesMount'])) {
			return;
		}
		if (isNullOrUndefined(changes['pagesMount'].currentValue)) {
			return;
		}
		if (changes['pagesMount'].currentValue === 0) {
			return;
		}
	}

	ngOnInit() {
	}

	nextPage() {
		this.paging.action = Paging.pagingAction.nextPage;
		this.emitPagingChanged();
	}

	setPages() {
		if (this.pagesMount <= 7) {
			this.startPage = 1;
			this.endPage = this.pagesMount;
		} else {
			if (this.currentPage <= 4) {
				this.startPage = 1;
				this.endPage = 7;
			} else if (this.currentPage + 4 >= this.pagesMount) {
				this.startPage = this.pagesMount - 6;
				this.endPage = this.pagesMount;
			} else {
				this.startPage = this.currentPage - 3;
				this.endPage = this.currentPage + 3;
			}
		}

		this.pages = Array((this.endPage) - this.startPage).fill(1).map((x, i) => this.startPage + i);
	}

	previousPage() {
		this.paging.action = Paging.pagingAction.previousPage;
		this.emitPagingChanged();
	}

	changeSize(value: number) {
		this.paging.size = value;
		this.emitPagingChanged();
	}

	emitPagingChanged() {
		if (!isNullOrUndefined(this.paging.loadData) && typeof this.paging.loadData === 'function') {
			this.paging.loadData();
		}
		this.pagingChanged.emit(this.paging);
	}

}
