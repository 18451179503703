<mat-nav-list>
	<ng-container *ngFor="let link of links" [ngTemplateOutlet]="linkItemListTemplate"
		[ngTemplateOutletContext]="{ link: link }">
	</ng-container>

	<br>
	<br>
	<br>
	<br>
	<br>

	<div fxLayout="column" fxLayoutAlign="end start" class="bg-primary w-100"
		style="border-radius: 16px; padding: 20px;" fxLayoutGap="10px">

		<h4>Get more menus for your restaurant</h4>

		<button mat-flat-button type="button"
			class="generic-btn">Go to Pro now!</button>
	</div>
</mat-nav-list>

<ng-template #linkItemListTemplate let-link='link'>

	<ng-container [ngTemplateOutlet]="linkItemTemplate" [ngTemplateOutletContext]="{ link: link }">
	</ng-container>

	<ng-container *ngIf="link.selected" [ngTemplateOutlet]="linkItemChildListTemplate"
		[ngTemplateOutletContext]="{ link: link }">
	</ng-container>

</ng-template>

<ng-template #linkItemChildListTemplate let-link='link'>

	<ng-container *ngFor="let item of link.children" [ngTemplateOutlet]="linkItemTemplate"
		[ngTemplateOutletContext]="{ link: item, child: true }">
	</ng-container>

</ng-template>

<ng-template #linkItemTemplate let-link='link' let-child='child'>

	<div mat-list-item fxLayoutGap="16px" fxLayout="row" fxLayoutAlign="start center" [routerLink]="link.path"
		(mouseenter)="link.hover = true" class="pointer btn-container" (mouseleave)="link.hover = false" [ngClass]="{'text-primary-lighter': link.selected, 'link-child': child, 'unselected': (!link.selected && !link.hover),
		'bg-primary-darker': (link.selected || child) && link.hover !== true }">

		<mat-icon [svgIcon]="link?.icon"></mat-icon>

		<div>
			<span *ngIf="child" class="vertical-divider bg-primary"></span>
			<span>{{link.label}}</span>
		</div>

	</div>

</ng-template>
