import { Paging, PagingOrderType } from "@pineapplelab/paging";
import { isObject } from "@pineapplelab/util";
import { ObjectSubmodel } from "../objectSubmodel";
import { IsNotEmpty } from '@pineapplelab/validators';
import { SlugifyModel } from "../slugifyModel";
import { Base } from "../base";

export class Menu extends SlugifyModel {

	@IsNotEmpty({ label: "Title" })
	name: string = null;

	categories: ObjectSubmodel[] = [];
	creator: ObjectSubmodel;
	address: string = null;
	instagram: string = null;
	facebook: string = null;
	youtube: string = null;
	tiktok: string = null;
	isActive: boolean = null;
	qrCode: string = null;

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}

	slugProperty(): string[] {
		return [this.name]
	}
}

export namespace Menu {

	export const PATH = 'menus';

	export const PAGING = new Paging<Menu>(<Paging.IPaging>{
		collection: PATH,
		preconditions: [
			{ fieldPath: 'is_deleted', filterOp: '==', value: false }
		],
		orderOptions: [
			{ field: 'name', label: 'title', placeholder: 'Filter by name', dataType: Paging.dataType.string },
		],
		orderBy: 'created_at',
		orderType: PagingOrderType.desc
	});
}
