<div fxLayout="row" fxLayoutAlign="end start" class="relative">
	<mat-icon class="pointer z-index" mat-dialog-close>close</mat-icon>
	<div class="main-container" mat-dialog-content>
		<div fxLayout="column">

			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
					<h2>My dishes</h2>
					<p>All are from the dishes library</p>
				</div>
			</div>

			<app-mat-table [cssClass]="'mat-elevation-z0'" class="minwidthTable" *ngIf="table.initialized"
				[hideDeletedRowsButton]="true" [table]="table" [template]="addToLesson" [showColDots]="false">
			</app-mat-table>

			<ng-template #activeColumn let-item="item" let-dataSource="dataSource">
				<mat-checkbox [checked]="item.active" name="active" (click)="addOrRemoveRelation(item)">
				</mat-checkbox>
			</ng-template>

			<ng-template #image let-item="item" let-dataSource="dataSource">
				<img style="width: 150px;" [src]="item?.coverFile" alt="">
			</ng-template>

			<ng-template #name let-item="item" let-dataSource="dataSource">
				{{ item?.name|| "Unknown" }}
			</ng-template>

			<ng-template #addToLesson let-item="item" let-dataSource="dataSource">
				<button (click)="saveInClass()" mat-flat-button class="bg-primary generic-btn text-primary-contrast">Add
					to category</button>
			</ng-template>

		</div>
	</div>
</div>
