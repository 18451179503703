import { DocumentReference } from "@angular/fire/firestore";
import { isObject } from "@pineapplelab/util";

export class ObjectSubmodel {

	id: string;
	name: string = null;
	isActive: boolean = true;
	reference: any = null;

	constructor(data?: { [key: string]: any }) {
		if (isObject(data)) {
			Object.keys(data).filter(field => field in this).forEach(field => (this as any)[field] = data[field]);
		}
	}
}
