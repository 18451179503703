import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class ScreenMediaService {

	private isMenuOpen = new BehaviorSubject<boolean>(false);

	getMenuOpen() {
		return this.isMenuOpen.asObservable();
	}

	setMenuOpen(value: boolean) {
		this.isMenuOpen.next(value);
	}

	mobileQuery = this.mediaMatch.matchMedia('screen and (max-width: 959px)');

	public _mobileQueryListener: () => void;

	constructor(
		public mediaMatch: MediaMatcher
	) { }

}
