import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from './services/theme/theme.service';
import { IconService } from './services/icon/icon.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { isNullOrUndefined } from '@pineapplelab/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	showSpinner: boolean;
	subscription: Subscription;

	constructor(
		public spinnerService: SpinnerService,
		private themeService: ThemeService,
		// private cd: ChangeDetectorRef,
		private iconService: IconService,
		// public translate: TranslateService,
		// private authService: AuthService,
		// private userService: UserService
	) {}

  async ngOnInit(): Promise<void> {
	this.themeService.setTheme('light-theme');
	// this.authService.isSigned().then(async response => {
	// 	if (response) {
	// 		await this.userService.updateUserLastLogin();
	// 	}
	// });

	this.subscription = this.spinnerService.spinnerVisible$.subscribe(value => {
		setTimeout(() => { this.showSpinner = value; }, 0);
	});

	// this.themesService.checkCurrentTheme();
	this.iconService.initializeAppIcons();
}

ngOnDestroy(): void {
	if (!isNullOrUndefined(this.subscription)) {
		this.subscription.unsubscribe();
	}
}

}
