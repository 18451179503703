<mat-form-field [appearance]="appearance" [ngClass]="{'mat-form-field-wrapper': noMargin }">
	<mat-label *ngIf="title != ''">{{title}}</mat-label>
	<mat-chip-grid #chipList>
		<mat-chip-row *ngFor="let content of selectedContent" [removable]="true" (removed)="remove(content)">
			<span *ngIf="content !== undefined && content !== null && content.length > -1">{{content}}</span>
			<span *ngIf="content !== undefined && content !== null && !(content.length > -1)">{{content[field]}}
				<small *ngIf="configForLegos" class="text-accent">
					({{content['__posts']}})
				</small>
			</span>
			<mat-icon matChipRemove>cancel</mat-icon>
		</mat-chip-row>
	</mat-chip-grid>

	<input #inp placeholder="{{search ? 'Search' : disabledInput ? '' : canCreate ? 'New content...' : label}}"
		[matChipInputFor]="chipList" (focus)="filter()" (input)="filter()" [(ngModel)]="filteredValue"
		[matAutocomplete]="auto" [disabled]="disabledInput" (matChipInputTokenEnd)="add($event)">

		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event); inp.value = '';">
		<mat-option [style.height]="showImage ? '60px' : null" *ngFor="let content of filteredContent" [value]="content"
			[disabled]="disabled">
			<img style="width: 40px" *ngIf="showImage && content !== undefined && content !== null" dynamicImage
				class="rounded mt-20 mr-10">
			<span [class.position-text-content]="showImage"
				*ngIf="content !== undefined && content !== null && content.length > -1">{{content}}</span>
			<span [class.position-text-content]="showImage"
				*ngIf="content !== undefined && content !== null && !(content.length > -1)">{{content[field]}}</span>
		</mat-option>
	</mat-autocomplete>


</mat-form-field>
