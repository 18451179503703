import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isNoEmptyArray, isNotEmptyString, isNullOrUndefined } from '@pineapplelab/util';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { Permissions } from 'src/app/enums/permissions.enum';

@Component({
	selector: 'app-side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss']
})

export class SideBarComponent implements OnInit, OnDestroy {

	links = this.navigationService.getBackOfficeLinks(this.authService.projectId);

	subscription: Subscription;

	constructor(
		private router: Router,
		private navigationService: NavigationService,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		let currentPath = this.router.url;
		this.selectedLink(currentPath);
		this.subscription = this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				currentPath = e.urlAfterRedirects;
				this.selectedLink(currentPath);
			}
		});
	}

	selectedLink(currentPath: string) {
		this.links.map(link => {
			link.selected = this.isItTheCurrentPath(link, currentPath);
			if (!isNoEmptyArray(link.children)) {
				return;
			}
			link.children.map(child => {
				child.selected = this.isItTheCurrentPath(child, currentPath);
				if (child.selected) {
					link.selected = true;
				}
			});
		});
	}

	isItTheCurrentPath(link: Partial<{ path: string, pathTemplate: string }>, currentPath: string) {
		if (currentPath === link.path) {
			return true;
		}
		if (!isNotEmptyString(link.pathTemplate)) {
			return false;
		}
		const segments = link.pathTemplate.split('/');
		const currentSegments = currentPath.split('/');
		if (currentSegments.length < 2) {
			return false;
		}
		return segments.every((segment, i) => segment.startsWith(':') ? true : currentSegments[i] === segment);
	}

	ngOnDestroy(): void {
		if (!isNullOrUndefined(this.subscription)) {
			this.subscription.unsubscribe();
		}
	}

	canAccess(permission: Permissions | Permissions[], inclusive?: boolean) {
		// return this.authService.hasPermission(permission, inclusive);
		return true;
	}

}
