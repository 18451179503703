// import moment from 'moment';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

	// year: number = moment().year();

	constructor(
		private router: Router,
	) { }
}
