<div fxLayout="column" fxLayoutAlign="space-between start" class="w-100">
	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.backgroundColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Background color</span>
				<b>{{model?.configuration?.backgroundColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('backgroundColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="backgroundColor.click()">
				<input type="color" value="#F25C05" #backgroundColor hidden
					(change)="onSelectColor($event, 'backgroundColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.primaryColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Primary color (selected button and social media icons)</span>
				<b>{{model?.configuration?.primaryColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('primaryColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="primaryColor.click()">
				<input type="color" value="#000000" #primaryColor hidden
					(change)="onSelectColor($event, 'primaryColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.borderColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Border color (Menu card border color)</span>
				<b>{{model?.configuration?.borderColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('borderColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="borderColor.click()">
				<input type="color" value="#000000" #borderColor hidden
					(change)="onSelectColor($event, 'borderColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.accentColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Accent color (unselected button)</span>
				<b>{{model?.configuration?.accentColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('accentColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="accentColor.click()">
				<input type="color" value="#000000" #accentColor hidden
					(change)="onSelectColor($event, 'accentColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.titleColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Title color</span>
				<b>{{model?.configuration?.titleColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('titleColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="titleColor.click()">
				<input type="color" value="#000000" #titleColor hidden
					(change)="onSelectColor($event, 'titleColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.descriptionColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Description color</span>
				<b>{{model?.configuration?.descriptionColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('descriptionColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="descriptionColor.click()">
				<input type="color" value="#000000" #descriptionColor hidden
					(change)="onSelectColor($event, 'descriptionColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.priceColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Price color</span>
				<b>{{model?.configuration?.priceColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('priceColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="priceColor.click()">
				<input type="color" value="#000000" #priceColor hidden
					(change)="onSelectColor($event, 'priceColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>

	<br>
	<mat-divider></mat-divider>
	<br>

	<div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<div class="colors" [style.background-color]="model?.configuration?.restaurantTitleColor">
			</div>

			<div fxLayout="column" fxLayoutAlign="center start">
				<span>Restaurant title and categories color</span>
				<b>{{model?.configuration?.restaurantTitleColor}}</b>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
			<span class="text-primary pointer" (click)="clearColor('restaurantTitleColor')">Clear</span>
			<div class="bg-primary icon pointer" (click)="restaurantTitleColor.click()">
				<input type="color" value="#000000" #restaurantTitleColor hidden
					(change)="onSelectColor($event, 'restaurantTitleColor')" />
				<mat-icon>edit a</mat-icon>
			</div>
		</div>

	</div>
</div>
