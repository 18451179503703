import { FBase } from "./firebase.base";
import { Paging } from "../paging";
import { isObject } from "@pineapplelab/util";

export class Role extends FBase {

	name: string = null;
	description: string = null;
	permissions: number[] = [];
	isDefault: boolean = null;

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}

}

export namespace Role {
	export const PATH = 'roles';

	export const PAGING = new Paging<Role>(<Paging.IPaging>{
		collection: PATH,
		orderOptions: [
			{ field: 'name', label: 'name', placeholder: 'Filter by name', dataType: Paging.dataType.string }
		],
		preconditions: [
			{ fieldPath: 'is_deleted', filterOp: '==', value: false },
			{ fieldPath: 'is_hidden', filterOp: '==', value: false }
		]
	});

}
