<div class="swiper-wrapper-container">

	<swiper-container loop="true" pagination="true">
		<swiper-slide *ngFor="let file of data.dishData.__dishMediaPreview">
			<img *ngIf="file.type == 'image'" [src]="file?.file" alt="Photo" (error)="onError($event)">
			<div *ngIf="file.type == 'video'" class="video-container" >
				<video [src]="file?.file" autoplay loop playsinline
				webkit-playsinline preload="metadata"></video>
			</div>
		</swiper-slide>
	</swiper-container>

	<button mat-dialog-close mat-fab class="close absolute">
		<mat-icon>arrow_back</mat-icon>
	</button>

	<div class="overlay"></div>

	<div class="card-content-preview">
		<h4 [ngStyle]="{ 'color': data?.configuration?.titleColor }" class="{{data?.configuration?.titleFont}}">{{
			data?.dishData.name }}</h4>
		<p [ngStyle]="{ 'color': data?.configuration?.priceColor }" style="font-size: 20px; font-weight: bold;">
			{{ (data?.dishData.currency == 'USD' ? '$' : '') + data.dishData.price }}</p>

		<div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="5px" class="icon-container">

			<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
				<ng-container *ngFor="let tag of data.dishData.__tag">
					<div class="icon" [ngSwitch]="tag?.tagType">
						<mat-icon *ngSwitchCase="0" svgIcon="app_gluten-free"></mat-icon>
						<mat-icon *ngSwitchCase="1" svgIcon="app_sugar-free"></mat-icon>
						<mat-icon *ngSwitchCase="2" svgIcon="app_dairy-free"></mat-icon>
						<mat-icon *ngSwitchCase="3" svgIcon="app_peanut-free"></mat-icon>
						<mat-icon *ngSwitchCase="4" svgIcon="app_seafood-free"></mat-icon>
						<mat-icon *ngSwitchCase="5" svgIcon="app_vegetarian"></mat-icon>
						<mat-icon *ngSwitchCase="6" svgIcon="app_vegan"></mat-icon>
						<mat-icon *ngSwitchCase="7" svgIcon="app_organic"></mat-icon>
						<mat-icon *ngSwitchCase="8" svgIcon="app_kosher"></mat-icon>
						<mat-icon *ngSwitchCase="9" svgIcon="app_mildly-spicy"></mat-icon>
						<mat-icon *ngSwitchCase="10" svgIcon="app_moderately-spicy"></mat-icon>
						<mat-icon *ngSwitchCase="11" svgIcon="app_very-spicy"></mat-icon>
					</div>
				</ng-container>
			</div>

			<br>

			<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
				<ng-container *ngFor="let allergy of data.dishData.__allergy">
					<div class="icon" [ngSwitch]="allergy?.allergyType">
						<mat-icon *ngSwitchCase="0" svgIcon="app_seafood"></mat-icon>
						<mat-icon *ngSwitchCase="1" svgIcon="app_peanut"></mat-icon>
						<mat-icon *ngSwitchCase="2" svgIcon="app_dairy"></mat-icon>
						<mat-icon *ngSwitchCase="4" svgIcon="app_seafood"></mat-icon>
					</div>
				</ng-container>
			</div>
		</div>

		<div>
			<p [ngStyle]="{ 'color': data?.configuration?.descriptionColor }">{{ data?.dishData.description }}</p>
		</div>
	</div>

</div>
