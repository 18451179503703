import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
	selector: 'app-success-payment',
	templateUrl: './success-payment.component.html',
	styleUrls: ['./success-payment.component.scss'],
})
export class SuccessPaymentComponent implements OnInit {
	constructor(private router: Router, private authService: AuthService) {}

	async ngOnInit(): Promise<void> {
		await this.authService.getSignedUserDoc(
			this.authService.auth?.user?.id
		);

		setTimeout(() => {
			this.router.navigateByUrl(`dashboard/menu`);
		}, 3000);
	}
}
