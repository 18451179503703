import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class NavigationContentService {

	private sidenavContentElement: HTMLElement;

	constructor() { }

	scrollIntoId(id: string) {
		this.sidenavContentElement?.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth' });
	}

	setSidenavContent(sidenavContentElement: HTMLElement) {
		this.sidenavContentElement = sidenavContentElement;
	}
}
