import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { isArray, isNoEmptyArray, isNotEmptyString, isNull, isNullOrUndefined, isObject, isValidFunction, isValidString } from '@pineapplelab/util';
import { ApiService } from '../api/api.service';
import { SpinnerService } from '../spinner/spinner.service';
import { ToasterService } from '../toaster/toaster.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FacebookAuthProvider, GoogleAuthProvider } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { DbService } from '../db/db.service';
import { User } from 'src/app/models/firebase/user';
import firebase from 'firebase/compat/app';
import { Auth } from 'src/app/models/auth';
import { Project } from 'src/app/models/firebase/project';
import { BrowserStorage } from 'src/app/models/browserStorage';
import slugify from 'slugify';
import { Permissions } from 'src/app/enums/permissions.enum';
import { NavigationLink } from 'src/app/interfaces/i-navigation-link';
import { NavigationService } from '../navigation/navigation.service';
import { noninclusiveUserMemberPermissions } from './user.permissions';
import { Role } from 'src/app/models/firebase/role';
import { RoleService } from '../role/role.service';
import { jwtDecode } from 'jwt-decode';
import { take } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	auth: { user: User, permissions: Permissions[], roles: Role[], isNewUser?: boolean, selectedRoleId: string } = {
		user: null,
		permissions: [],
		roles: [],
		selectedRoleId: null,
	};
	urlAfterLogin: string = null;
	projectId = environment.projectId;
	today = new Date();
	membersAreaPath = '/user/dashboard';
	externalMethod: string
	userData: any;

	constructor(
		private router: Router,
		private api: ApiService,
		private AngularFireAuth: AngularFireAuth,
		private ngZone: NgZone,
		private db: DbService,
		private toasterService: ToasterService,
		private spinnerService: SpinnerService,
		private navigationService: NavigationService,
		private roleService: RoleService,
	) {
		this.AngularFireAuth.authState.subscribe((user) => {
			if (user) {
				this.userData = user;
				localStorage.setItem('user', JSON.stringify(this.userData))
			} else {
				localStorage.setItem('user', 'null')
			}
		})
	}

	async signUpWithEmail(user: any) {
		return this.api.post('/auth/signup', { ...user }).then(response => {
			if (!isObject(response) || (!isObject(response.data) && isNullOrUndefined(response.error))) {
				return null;
			}
			return response;
		});
	}

	async externalLogin(provider: 'facebook' | 'google' = 'google') {

		// const externalLog = await this.externalLoginPopup(provider)

		// if (isNullOrUndefined(externalLog)) {
		// 	this.toasterService.showError('Error al iniciar sesion con google')
		// 	return
		// }

		// this.observeUserState()
		// const user = await this.saveNewUser(externalLog)

		// if (isNullOrUndefined(user)) {
		// 	this.toasterService.showError('error al guardar usuario')
		// }

	}

	private externalLoginPopup(provider: 'google' | 'facebook') {
		let authProvider = null;
		switch (provider) {
			case 'facebook':
				authProvider = new FacebookAuthProvider();
				break;
			default:
				authProvider = new GoogleAuthProvider();
				break;
		}
		return this.AngularFireAuth.signInWithPopup(authProvider);
	}

	// observeUserState() {
	// 	this.AngularFireAuth.authState.subscribe((userState) => {
	// 		userState && this.ngZone.run(() => this.router.navigate(['/']))
	// 	})
	// }

	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem('user')!);
		return !isNullOrUndefined(user);
	}

	async logOut(avoidRedirect = false) {
		this.auth.user = null;
		this.auth.permissions = [];
		this.auth.isNewUser = false;
		this.auth.roles = [];
		BrowserStorage.delete('userToken');
		return this.AngularFireAuth.signOut().finally(() => {
			this.toasterService.show("Logged out successfully", "Success");
			this.router.navigate(['/'])
			return true;
		});
	}

	async registerLogin(accessToken: string) {
		const loggedUser = await this.signMeIn(accessToken);

		this.router.navigateByUrl('/account/payment');

		return loggedUser
	}

	async signMeIn(token: string) {
		const response = await this.AngularFireAuth.signInWithCustomToken(token);
		BrowserStorage.set('userToken', token);
		await this.getAuthPermissions(response.user);
		return this.getSignedUserDoc(response.user.uid);
	}

	private async getAuthPermissions(user: firebase.User) {
		const idToken = await user.getIdTokenResult();
		this.auth.permissions = isArray(idToken.claims['permissions']) ? idToken.claims['permissions'] : [];
		this.auth.selectedRoleId = isNotEmptyString(idToken?.claims?.['roleId']) ? idToken?.claims?.['roleId'] : '';
		this.auth.isNewUser = idToken?.claims?.['isNewUser'];
	}

	async getSignedUserDoc(userId: string) {
		const userDoc = await this.db.firestore.collection(Project.PATH).doc(this.projectId)
			.collection(User.PATH).doc(userId).get();
		if (userDoc.exists) {
			this.auth.user = new User({ ...userDoc.data(), defaultProject: this.projectId });
			await this.getMyRoles();
			return this.auth.user;
		}
		return null;
	}

	async getMyRoles() {
		if (isNullOrUndefined(this.auth?.user?.id)) {
			return;
		}
		const roles = await this.roleService.getRolesByUserId(this.projectId, this.auth.user.id);
		if (isNoEmptyArray(roles)) {
			this.auth.roles = roles;
		}
	}

	async login(user: { email: string, password: string }, redirectAfterLogin?: string) {
		const loggedUser = await this.api.post('/auth/login', { ...user }).then(async r => {
			if (!isObject(r) || !isObject(r.data) || !isNotEmptyString(r.data.accessToken)) {
				return null;
			}
			return await this.signMeIn(r.data.accessToken);
		});

		this.router.navigateByUrl('/dashboard/menu');

		return loggedUser
	}

	async isSigned() {
		if (!this.isJWTValid()) {
			return false;
		}
		if (this.auth.user) {
			return true;
		}
		const firebaseUser = await this.AngularFireAuth.user.pipe(take(1)).toPromise()
		if (isNullOrUndefined(firebaseUser)) {
			return false;
		}
		const user = await this.getSignedUserDoc(firebaseUser.uid);
		await this.getAuthPermissions(firebaseUser);
		return !isNullOrUndefined(user);
	}

	isJWTValid() {
		const token = BrowserStorage.get('userToken');
		if (!isValidString(token)) {
			return false;
		}
		const decodedToken: any = jwtDecode(token);
		const tokenDurationHoursAgo = Date.now() - ((environment?.tokenDurationHours ? environment?.tokenDurationHours - 1 : 71) * 60 * 60 * 1000);
		const isTokenValid = tokenDurationHoursAgo < decodedToken.exp * 1000;

		if (!isTokenValid) {
			BrowserStorage.delete('userToken');
		}

		return isTokenValid;
	}
}
