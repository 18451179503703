import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { isNotEmptyString } from '@pineapplelab/util';

@Pipe({
	name: 'safeUrl'
})

export class SafeUrlPipe implements PipeTransform {

	constructor(
		private domSanitizer: DomSanitizer
	) { }

	transform(value: string, type: 'html' | 'url' | 'resource' | 'style' | 'script' = 'url', defaultValue: string = null) {
		if (!isNotEmptyString(value)) {
			return defaultValue;
		}
		let trueUrl: SafeUrl | SafeScript | SafeStyle | SafeResourceUrl | SafeHtml = '';
		switch (type) {
			case 'script':
				trueUrl = this.domSanitizer.bypassSecurityTrustScript(value);
				break;
			case 'style':
				trueUrl = this.domSanitizer.bypassSecurityTrustStyle(value);
				break;
			case 'resource':
				trueUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
				break;
			case 'html':
				trueUrl = this.domSanitizer.bypassSecurityTrustHtml(value);
				break;
			default:
				trueUrl = this.domSanitizer.bypassSecurityTrustUrl(value);
				break;
		}
		return trueUrl;
	}

}
