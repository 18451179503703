export enum Permissions {
	/** AUTH_GET_TOKEN_S3 */
	AUTH_GET_TOKEN_S3 = 1,
	/** UPLOAD_GET */
	UPLOAD_GET = 2,
	/** UPLOAD_GET_ALL */
	UPLOAD_GET_ALL = 3,
	/** UPLOAD_GET_CONTENT */
	UPLOAD_GET_CONTENT = 4,
	/** UPLOAD_POST */
	UPLOAD_POST = 5,
	/** USER_UPDATE */
	USER_UPDATE = 6,
	/** USER_CHANGE_PASSWORD */
	USER_CHANGE_PASSWORD = 7,
	/** USER_DELETE */
	USER_DELETE = 8,
	/** Access to permission CRUD view */
	ReadPermission = 9,
	/** Edit permission model except code. */
	EditPermission = 10,
	/** Create new permission */
	CreatePermission = 11,
	/** Create new role */
	CreateRole = 12,
	/** Edit role model */
	EditRole = 13,
	/** Access to role CRUD view */
	ReadRoles = 14,
	/** Delete role */
	DeleteRole = 15,
	/** Allow access to courses CRUD view */
	ReadAllCourse = 16,
	/** Allow access to classes CRUD view */
	ReadAllClass = 17,
	/** Allow assign roles to a user. */
	AssignRole = 18,
	/** Allow read all users for assign roles */
	ReadAllUsers = 19,
	/** Allow access to client view. */
	ReadClients = 20,
	/** Allow access to assessments CRUD view */
	ReadAllAssessment = 21,
	/** Allow access to tasks CRUD view  */
	ReadAllTask = 22,
	/** Allow access to segments CRUD view */
	ReadAllSegment = 23,
	/** Allow access to movementes CRUD view */
	ReadAllMovement = 24,
	/** Allow access to tags CRUD view */
	ReadAllTag = 25,
	/** Allow access to categories CRUD view */
	ReadAllCategory = 26,
	/** Allow create assessment */
	CreateAssessment = 27,
	/** Allow edit assessments */
	EditAssessment = 28,
	/** Allow delete Assessment */
	DeleteAssessment = 29,
	/** Allow create tasks */
	CreateTask = 30,
	/** Allow edit tasks */
	EditTask = 31,
	/** Allow delete tasks */
	DeleteTask = 32,
	/** Allow create courses */
	CreateCourse = 33,
	/** Allow edit courses */
	EditCourse = 34,
	/** Allow delete courses */
	DeleteCourse = 35,
	/** Allow create classes */
	CreateClass = 36,
	/** Allow edit classes */
	EditClass = 37,
	/** Allow delete classes */
	DeleteClass = 38,
	/** Allow create segments */
	CreateSegment = 39,
	/** Allow edit segments */
	EditSegment = 40,
	/** Allow delete segments */
	DeleteSegment = 41,
	/** Allow create movements */
	CreateMovement = 42,
	/** Allow edit movements */
	EditMovement = 43,
	/** Allow delete movements */
	DeleteMovement = 44,
	/** Allow create create tag categories */
	CreateTagCategory = 45,
	/** Allow edit tag categories */
	EditTagCategory = 46,
	/** Allow delete tag categories */
	DeleteTagCategory = 47,
	/** Allow create course categories */
	CreateCourseCategory = 48,
	/** Allow edit course categories */
	EditCourseCategory = 49,
	/** Allow delete course categories */
	DeleteCourseCategory = 50,
	/** Allow assign coaches */
	AssignCoach = 51,
	/** Allow access to client details view */
	ReadClientDetails = 52,
	/** Show the user as available coach to be assigned. */
	ShowAsAvailableCoach = 53,
	/** Allow approve or reject assigned clients */
	ApproveOrRejectAssignedClients = 54,
	/** Allow request video or delete request */
	RequestSegmentVideo = 55,
	/** Allow read profile  assessments videos */
	ReadProfileAssessmentsVideos = 56,
	/** Allow read profile courses */
	ReadProfileCourses = 57,
	/** Allow view dashboard */
	ViewDashboard = 58,
	/** Allow view last interaction */
	ViewLastInteraction = 59,
	/** Allow view client videos */
	ViewClientVideos = 60,
	/** Allow view newly assigned clients */
	ViewNewlyAssignedClients = 61,
	/** Allow view recording request */
	ViewRecordingRequest = 62,
	/** Allow view ignored request */
	ViewIgnoredRequest = 63,
	/** Allow view activity logs */
	ViewActivityLogs = 64,
	/** Allow view coach statistics */
	ViewCoachStatistics = 65,
	/** Allow annotate segment submission video */
	AnnotateSegmentSubmission = 66,
	/** Allow recommend courses or movements */
	Recommend = 67,
	/** Allow access to client view but show only assigned clients */
	ReadOnlyAssignedClients = 68,
	/** Allow to read all the introductions videos */
	ReadIntroductionMedia = 69,
	/** Allow create introduction media */
	CreateIntroductionMedia = 70,
	/** Allow edit introduction media */
	EditIntroductionMedia = 71,
	/** Allow delete introduction media */
	DeleteIntroductionMedia = 72,
	/** Allow upload or record a welcome video for every assigned client. */
	SendWelcomeVideo = 73,
	/** Allow update Highlights in profile view */
	EditProfileHighlights = 74,
	/** Allow update injuries in profile view. */
	EditProfileInjuries = 75,
	/** Allow update objective and focus in profile view */
	EditProfileObjectiveAndFocus = 76,
	/** Allow update personality, ability, skill and resource access in the profile view */
	EditProfileAssessments = 77,
	/** Allow view ToDo actions that are assigned to noone in the profile view. */
	ReadClientUnassignedToDoActions = 78,
	/** Allow view ToDo actions that are assigned to me in the profile view. */
	ReadClientToDoActionAssignedToMe = 79,
	/** Allow update personal information */
	editAuthUser = 80,
	/** Allow view auth profile */
	ViewAuthProfile = 81,
	/** Allow update auth profile image */
	UpdateAuthProfileImage = 82,
	/** Allor to read recommendation media */
	ReadRecommendationMedia = 83,
	/** Allow to create recommendation media */
	CreateRecommendationMedia = 84,
	/** Allow edit recommendation media */
	EditRecommendationMedia = 85,
	/** Allow delete recommendation media */
	DeleteRecommendationMedia = 86,
	/** Allow coach request temporal coach */
	RequestTemporalCoach = 87,
	/** Coach only can touch the todo list  */
	CanTouchToDoList = 88,
	/** Allow request new permanent coach */
	RequestPermanentCoach = 89,
	/** Allow view assigner to do list */
	AllowViewAssignerToDoList = 90,
	/** Allow read all clients */
	ReadAllClients = 91,
	/** Allow do actions as operator  */
	ActionsAsOperator = 92,
	/** Allow set a role and see what they see */
	AllowRoleChange = 93,
	/** Allow view account page */
	ViewAccountPage = 94,
	/** Allow delete a my media file ( image or video) */
	DeleteMyMedia = 95,
	/** Allow upload my media files (image or video)  */
	UploadMyMedia = 96,
	/** Allow the user to change their password */
	ChangePassword = 97,
	/** Allow see a general todo list. */
	SeeGeneralTodoList = 98,
	/** allow user go to change password */
	ChangePasswordView = 99,
	/** Allow user change credit card information */
	EditPaymentDetails = 100,
	/** Allow the user change personal information in account page */
	EditPersonalInformationView = 101,
	/** Allow user change membership  */
	ManageMembershipView = 102,
	/** Allow user cancel subscription */
	CancelSubscription = 103,
	/** Allow access to links CRUD view */
	ReadAllLinks = 104,
	/** Allow edit links */
	EditLinks = 105,
	/** Allow create links */
	CreateLinks = 106,
	/** Allow delete links */
	DeleteLinks = 107,
	/** Allow user see button "Add Recommendation" */
	SeeRecommendationButton = 108,
	/** Allow user to see UI themes option on dropdown */
	ViewUiThemesDropdown = 109,
	/** Allow edit tag */
	EditTag = 110,
	/** Allow delete tag */
	DeleteTag = 111,
	/** Allow access to clubs CRUD view */
	ReadAllClubs = 112,
	/** Allow create club */
	CreateClub = 113,
	/** Allow edit club */
	EditClub = 114,
	/** Allow delete club */
	DeleteClub = 115,
	/** Allow access to events CRUD view */
	ReadAllEvents = 116,
	/** Allow create events */
	CreateEvents = 117,
	/** Allow edit events */
	EditEvents = 118,
	/** Allow delete events */
	DeleteEvents = 119,
	/** Allow access to articles CRUD view */
	ReadAllArticles = 120,
	/** Allow create articles */
	CreateArticles = 121,
	/** Allow edit articles */
	EditArticles = 122,
	/** Allow delete articles */
	DeleteArticles = 123,
	/** Allows edit of workshops modality */
	AllowEditModality = 124,
	/** Allows delete of workshops modality */
	AllowDeleteModality = 125,
	/** Allow view of editorial management */
	ViewEditorialManagement = 126,
	/** Allow create tag */
	CreateTag = 127,
	/** USER_SUPPORTER */
	USER_SUPPORTER = 128,
	/** Allows the user to review a workshop (assignments or questions) */
	ReviewWorkshop = 129,
	/** Allows view of page management (editorial page) */
	ViewPageManagement = 130,
	/** Allows editorial page creation */
	CreateEditorialPage = 131,
	/** Allows editorial page edition */
	EditEditorialPage = 132,
	/** Allows delete no removable content */
	DeleteNoRemovableContent = 133,
	/** Allow admin to show key value from json */
	CAN_SHOW_KEYS = 134,
	/** User generated by an admin, can browse the application differently */
	AdminGenerated = 135,
	/** Allows the user to review all workshops (assignments or questions) */
	ReviewAllWorkshops = 136,
	/** Read business analytics */
	ReadBusinessAnalytics = 137,
	/** Permission for Peppermint expert to chat with everyone */
	ViewAllUsersChat = 138,
	/** Can see my workshops catalog */
	ViewMyWorkshops = 139,
	/** Can see my bookmarks */
	ViewMyBookmarks = 140,
	/** Can see my clubs catalog */
	ViewMyClubs = 141,
	/** Can see my friends */
	ViewMyFriends = 142,
	/** Can see my profile */
	ViewMyProfile = 143,
	/** Can see notifications */
	ViewNotifications = 144,
	/** Permission for expert drop down */
	ViewExpertDropDown = 145,
	/** Permssions for workshop classroom (instructor) drop down */
	ViewWorkshopClassroomDropDown = 146,
	/** Allows user management role to change users payment settings */
	ManageUsersPayment = 147,
	/** Allows the deletion of feed posts in the dashboard */
	AllowDeletePosts = 148,
	/** Allow access to releases CRUD view */
	ReadAllReleases = 149,
	/** Allow create releases */
	CreateRelease = 150,
	/** Allow edit releases */
	EditRelease = 151,
	/** Allow edit releases */
	DeleteRelease = 152,
	/** Permission for oluko admin */
	OlukoAdmin = 153,
	/** Allows the deletion of comments from posts */
	AllowCommentsDelete = 154
};
