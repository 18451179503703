import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit {

	mobileExit = 'isMobile';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string, question: string, falseButton: string, trueButton: string, param: string },
		public dialogRef: MatDialogRef<AlertComponent>
	) { }

	ngOnInit(): void {
	}

	cancel() {
		this.dialogRef.close(false);
	}

	accept() {
		this.dialogRef.close(true);
	}

}
