<div fxLayout="row" fxLayoutAlign="center center">
    <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px" *ngIf="!hidePageSize">
        <span>{{itemsPerPageLabel}}</span>
        <mat-form-field *ngIf="paging.sizeOptions.length > 1" class="mat-paginator-page-size-select">
            <mat-select [(value)]="paging.size" [aria-label]="itemsPerPageLabel"
                (selectionChange)="changeSize($event.value)">
                <mat-option *ngFor="let pageSizeOption of paging.sizeOptions" [value]="pageSizeOption">
                    {{pageSizeOption}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="paging.sizeOptions.length <= 1">
            {{paging.size}}
        </div>
    </div> -->

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <button mat-icon-button (click)="previousPage()" [attr.aria-label]="previousPageLabel"
            [matTooltip]="previousPageLabel" [matTooltipPosition]="'above'" [disabled]="!paging.response.hasLess"
            type="button">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>

		<div class="current-page bg-primary white">
			<span>{{paging?.currentPageDisplayed}}</span>
		</div>

        <button mat-icon-button [ngClass]="{ 'mr-negative-1': marginRight }" (click)="nextPage()"
            [attr.aria-label]="nextPageLabel" [matTooltip]="nextPageLabel" [matTooltipPosition]="'above'"
            [disabled]="!paging.response.hasMore" type="button">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>

</div>
