import { Component, EventEmitter, Input, Output } from '@angular/core';
import { configuration } from 'src/app/models/firebase/user';

@Component({
  selector: 'app-select-color',
  templateUrl: './select-color.component.html',
  styleUrls: ['./select-color.component.scss']
})

export class SelectColorComponent {

	@Input() model: any

	@Output() selectColor = new EventEmitter<any>();

	onSelectColor(event: any, property?: keyof configuration) {
		const data = event.target.value as string

		const colorObj = {
			property: property,
			color: data
		}

		this.selectColor.emit(colorObj)
	}

	clearColor(property?: keyof configuration) {
		this.model.configuration[property] = null
	}

}
