import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { isNullOrUndefined } from '@pineapplelab/util';

@Component({
	selector: 'app-drag-drop-sorting',
	templateUrl: './drag-drop-sorting.component.html',
	styleUrls: ['./drag-drop-sorting.component.scss']
})

export class DragDropSortingComponent implements OnInit, OnChanges {

	@Input() list: { [key: string]: any, label?: string }[] = [];
	@Input() field = 'label';
	@Input() title = 'label';
	@Input() template: TemplateRef<any>;
	@Input() templateContext: any = null;
	@Input() indexedTitle: string = '';
	@Input() expansion: boolean = false;
	@Input() editorialMode: { active: boolean, clubActivityFeedState: { [key: string]: boolean } } = { active: false, clubActivityFeedState: null };
	@Input() showIndexedTitle: boolean = true;
	@Input() titleByObject: { object: any, field: string, type?: any } = null;
	@Input() showRemove = true;
	@Input() showDivider: boolean = true;
	@Input() dynamicTitle: boolean = false;
	@Input() isExpanded: boolean[] = [];
	@Input() ownTitle: { active: boolean, field: string, [key: string]: any } = { active: false, field: null };
	@Input() width: number;

	@Output() removed = new EventEmitter<any>();
	@Output() updatedList = new EventEmitter<any>();
	@Output() isExpandedList = new EventEmitter<any>();
	@Output() threeSlotCarouselMode = new EventEmitter<{ mode: 'auto' | 'manual', index: number }>();

	panelOpenState = false;
	contextValue: any;
	modalitiesDictionary: any = {};
	activitiesDictionary: any = {};

	constructor() { }

	ngOnInit(): void {

		if (!isNullOrUndefined(this.templateContext)) {
			this.contextValue = Object.values(this.templateContext)[0];
		}

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (isNullOrUndefined(changes['unenrolled'])) {
			return;
		}

	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.list, event.previousIndex, event.currentIndex);
		this.isExpandedList.emit(this.isExpanded)
		if (this.isExpanded.length > 0) {
			moveItemInArray(this.isExpanded, event.previousIndex, event.currentIndex)
			this.updatedList.emit(this.isExpanded)
		}
	}

	remove(item: any, i?: number) {
		item.__index = i;
		this.removed.emit(item);
		if (this.isExpanded.length > 0) {
			this.isExpanded.splice(1, i)
			this.updatedList.emit(this.isExpanded)
		}
	}

	setItemsState(list: any[]) {
		this.list.forEach((item) => {
			item['__expanded'] = false;
		})
	}

	emitThreeSlotCarouselMode(mode: 'auto' | 'manual', index: number): void {
		this.threeSlotCarouselMode.emit({ mode, index });
	}
}
