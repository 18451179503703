<mat-toolbar fxLayout="row" class="w-100" [ngClass]="dashboardArea ? 'header-container' : ''">
	<div fxLayout="row" fxLayoutAlign="space-between center" class="container padding w-100">

		<ng-content></ng-content>

		<div fxLayoutAlign="center center" class="pointer" fxLayoutGap="10px">
			<img class="logo" src="assets/logo.png" [routerLink]="['/']">
			<h4>Webnu</h4>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2vw" *ngIf="!auth.user">

			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">

				<ng-container *ngIf="smallScreen" class="w-100">
					<button mat-icon-button
						(click)="openSmall = !openSmall; screenMediaService.setMenuOpen(openSmall);">
						<mat-icon class="text-primary-darker">menu</mat-icon>
					</button>

					<mat-sidenav *ngIf="smallScreen" class="menuResponsive pa-10" [disableClose]="true" #sidenav
						mode="over" position="end" [opened]="openSmall || openContent">
						<mat-sidenav-container class="menu-responsive-container" fxLayout="column">
							<div fxLayout="row" fxLayoutAlign="space-between center" class="container">
								<img src="assets/logo.png" alt="logo" class="pointer logo" [routerLink]="['/']">

								<button mat-icon-button class="close p-0" (click)="setSmallFalse()">
									<mat-icon class="text-primary-darker">close</mat-icon>
								</button>
							</div>
							<br>
							<ng-container *ngTemplateOutlet="openSmall ? menuTemplate : null"></ng-container>
						</mat-sidenav-container>
					</mat-sidenav>

					<ng-template matMenuContent #menuTemplate>
						<ng-container>

							<div *ngIf="smallScreen" fxLayout="column" fxLayoutAlign="space-between center"
								fxLayoutGap="20px">

								<button mat-button color="primary" [disableRipple]="true"
									class="text-primary responsive-menu-btn" (click)="setSmallFalse()"
									[routerLink]="['/auth/login']">Login</button>

								<button mat-button color="primary" [disableRipple]="true"
									class="text-primary responsive-menu-btn" (click)="setSmallFalse()"
									[routerLink]="['/auth/register']">Register</button>

							</div>

						</ng-container>
					</ng-template>
				</ng-container>

				<ng-container *ngIf="!smallScreen">
					<button mat-flat-button type="button" class="generic-btn border-primary text-primary"
						[routerLink]="['/auth/login']">
						Login
					</button>

					<button mat-flat-button type="button" class="generic-btn border-primary text-primary"
						[routerLink]="['/auth/register']">
						Register
					</button>
				</ng-container>

			</div>
		</div>

		<div fxLayout="row" fxLayoutGap="2vw" *ngIf="auth.user">

			<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">

				<span *ngIf="!smallScreen">{{auth.user?.restaurantName | titlecase}}</span>

				<div #menuTrigger="matMenuTrigger" [mat-menu-trigger-for]="menu"
					class="pointer profile-photo border-primary">
					<img *ngIf="auth.user?.avatar" [src]="auth.user?.avatar" alt="..." class="profile-image rounded">
					<img *ngIf="!auth.user?.avatar" src="assets/logo.png" alt="..." class="profile-image rounded">
				</div>

			</div>

		</div>

		<mat-menu #menu="matMenu" xPosition="before">
			<div>
				<h4 class="center" *ngIf="smallScreen">{{auth.user?.restaurantName | titlecase}}</h4>

				<button mat-menu-item (click)="dashboard()">
					<span>Dashboard</span>
				</button>

				<mat-divider></mat-divider>

				<button mat-menu-item (click)="logout()">
					Sign out
				</button>
			</div>
		</mat-menu>

	</div>
</mat-toolbar>
