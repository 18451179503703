<div fxLayout="column">
	<h4 mat-dialog-title *ngIf="data?.param != ''">
		{{ data?.title }}
	</h4>
	<h4 mat-dialog-title *ngIf="data?.param == ''">
		{{ data?.title }}
	</h4>
	<div *ngIf="data?.question" mat-dialog-content>
		{{data?.question }}
	</div>
	<br>
	<div mat-dialog-actions fxLayoutAlign="end center">
		<button mat-stroked-button mat-dialog-close (click)="cancel()" *ngIf="data?.question"
			class="border-primary generic-btn text-primary" color="primary" type="button">
			{{data?.falseButton || 'Cancel'}}
		</button>
		<button mat-flat-button (click)="accept()" *ngIf="data?.question" class="generic-btn" color="primary"
			type="button">
			{{data?.trueButton || 'Delete'}}
		</button>
	</div>
	<br>
</div>
