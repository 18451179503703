import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ScreenMediaService } from 'src/app/services/screen-media/screen-media.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

	auth = this.authService.auth;
	dashboardArea: boolean
	openSmall = false;
	openAvatarSmall = false;
	smallScreen = false;
	mobileQuery: MediaQueryList;
	openContent = false;

	constructor(
		private authService: AuthService,
		private router: Router,
		private breakpointObserver: BreakpointObserver,
		public screenMediaService: ScreenMediaService,
	) { }

	async ngOnInit() {

		this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(screen => {
			this.smallScreen = screen.matches
		});

		if (this.router.url.includes('/dashboard')) {
			this.dashboardArea = true
		}

		if (!this.auth.user) {
			if (await this.authService.isSigned()) {
				this.auth = this.authService.auth
			}
		}
	}

	setSmallFalse() {
		this.openSmall = false;
		this.openContent = false;
		this.openAvatarSmall = false;
		this.screenMediaService.setMenuOpen(false);
	}

	logout() {
		this.authService.logOut();
	}

	dashboard() {
		this.router.navigateByUrl('/dashboard/menu');
	}

}
