import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@pineapplelab/util';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
	providedIn: 'root'
})

export class StorageService {

	constructor(
		private fireStorage: AngularFireStorage,
	) { }

	uploadFile(path: string, file: File, metadata?: firebase.default.storage.UploadMetadata) {
		if (isNullOrUndefined(metadata)) {
			metadata = { contentType: file.type };
		}
		return this.fireStorage.upload(path, file, metadata).then((r) => r.ref.getDownloadURL());
	}

	getImageUrl(imagePath: string) {
		const urlImg = this.fireStorage.storage.ref(imagePath).getDownloadURL()
		return urlImg
	}

}
