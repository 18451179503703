import { Injectable } from '@angular/core';
import { DbService } from '../db/db.service';
import { Project } from 'src/app/models/firebase/project';
import { Dishes } from 'src/app/models/firebase/dishes';
import { getFileNameExtension, isNullOrUndefined } from '@pineapplelab/util';
import { StorageService } from '../storage/storage.service';

@Injectable({
	providedIn: 'root'
})
export class DishesService {

	constructor(
		private db: DbService,
		private storageService: StorageService,
	) { }

	async save(model: Dishes, projectId: string) {

		if (isNullOrUndefined(model.id)) {
			model.id = this.db.createId();
		}

		await this.saveImages(model, projectId);
		const json = model.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Dishes.PATH).doc(model.id).set(json, { merge: true });

		return json;
	}

	delete(projectId: string, modelId: string) {
		const json = { is_deleted: true, deleted_at: this.db.serverTimeStamp() };
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(Dishes.PATH).doc(modelId).set(json, { merge: true });
	}

	private async saveImages(model: Dishes, projectId: string) {
		const commonPath = `${Project.PATH}/${projectId}/${Dishes.PATH}/${model.id}`;
		model.image = []

		if (!isNullOrUndefined(model.__fileCover)) {
			const pathCoverImage = `${commonPath}/cover_${model.__fileCover.name}.${getFileNameExtension(model.__fileCover.name)}`;

			if (model.__fileCover.type == 'video/mp4') {
				model.coverFile = await this.storageService.uploadFile(pathCoverImage, model.__fileCover, { contentType: 'video/mp4' })
			} else {
				model.coverFile = await this.storageService.uploadFile(pathCoverImage, model.__fileCover, { contentType: 'image/jpeg' })
			}
		}

		if (model.__file.length > 0) {
			for (const imageFile of model.__file) {
				const pathImage = `${commonPath}/${imageFile.name}.${getFileNameExtension(imageFile.name)}`;

				let data

				if (imageFile.type == 'video/mp4') {
					data = await this.storageService.uploadFile(pathImage, imageFile, { contentType: 'video/mp4' })
				} else {
					data = await this.storageService.uploadFile(pathImage, imageFile, { contentType: 'image/jpeg' })
				}

				model.image.push(data)
			}
		}

	}

	docReference(projectId: string, dishesId: string) {
		const path = this.db.root.collection(Project.PATH).doc(projectId).collection(Dishes.PATH).doc(dishesId).ref.path;
		return this.db.firestore.doc(path);
	}
}
