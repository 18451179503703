import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { isNullOrUndefined } from "@pineapplelab/util";
/**
 * @class AppErrorStateMatcher
 * The `AppErrorStateMatcher` class is an implementation of the `ErrorStateMatcher` interface in
 * Angular that determines whether a form control has an error state based on the model's error state.
 */
export class AppErrorStateMatcher implements ErrorStateMatcher {

 /**
  * The constructor function takes in a model and a field as parameters and assigns them to private
  * properties.
  * @param {any} model - The "model" parameter is of type "any", which means it can accept any data
  * type. It is used to store the model object that will be used in the constructor.
  * @param {string} field - The "field" parameter is a string that represents the name of a field or
  * property in the model.
  */
  constructor(
    private model: any,
    private field: string
  ) {}

  /**
   * The function `isErrorState` checks if a form control has an error state based on its value and the
   * form it belongs to.
   * @param {FormControl | null} control - The `control` parameter is of type `FormControl | null`. It
   * represents the form control that is being checked for error state. It can be either a
   * `FormControl` instance or `null`.
   * @param {FormGroupDirective | NgForm | null} form - The `form` parameter is of type
   * `FormGroupDirective | NgForm | null`. It represents the form group or form directive that contains
   * the control.
   * @returns a boolean value.
   */
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const hasErrorByField = isNullOrUndefined( this.model ) || isNullOrUndefined(this.model.__errorByField);
	const isUsed = (control?.dirty || control?.touched) || false;

	if (control) {
        return isUsed && (hasErrorByField || !isNullOrUndefined(this.model.__errorByField[this.field]));
    } else {
        return false;
    }
  }

}
