import firebase from 'firebase/compat/app';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isNoEmptyArray, isNullOrUndefined, isValidFunction } from '@pineapplelab/util';
import { Table } from 'src/app/models/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { CrudService } from 'src/app/services/crud/crud.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AlertComponent } from '../alert/alert.component';
import { CRUDModel } from 'src/app/models/crud';
import moment from 'moment';
import { QRCodeElementType } from 'angularx-qrcode';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/firebase/user';

@Component({
	selector: 'app-crud',
	templateUrl: './crud.component.html',
	styleUrls: ['./crud.component.scss'],
})

export class CrudComponent implements AfterViewInit, OnInit {

	@Input() crud: CRUDModel<any>;
	@Input() allowCreate: boolean = true;
	@Input() popUpVersion: boolean = true;
	@Input() alertMessage: { title?: string; question?: string };
	@ViewChild('options') options: ElementRef;
	@ViewChild('bulkDelete') bulkDelete: ElementRef;
	@Input() showBulkDelete: boolean = false;
	@Input() chooseColumns: boolean = true;
	@Input() deleteMessage: string;
	@Input() deleteBulkMessage: string;
	@Input() hideFilter: boolean = false;
	@Input() activeOptions: {
		active: boolean;
		addToLabel: string;
		crud: { [key: string]: any };
		removeOptions: boolean;
	} = { active: false, addToLabel: null, crud: null, removeOptions: true };
	@Input() additionalCrudOptions: {
		visibility: boolean;
		copy: boolean;
		dynamicEdit: boolean;
	} = { visibility: false, copy: false, dynamicEdit: false };
	@Input() expandable: {
		active: boolean;
		padding: boolean;
		template?: TemplateRef<any>;
	} = { active: false, padding: true };
	@Input() columnLimit: number = 5;
	@Input() hideNewButton: boolean = false;
	@Input() showColDots: boolean = true;
	@Input() hiddenSearch: boolean = false;
	@Input() hiddenBulkDelete: boolean = false;
	@Input() hideTableFooter: boolean = false;
	@Input() hideDeleteRow: boolean = false;
	@Input() showDeleteRows: boolean = false;
	@Input() isMenu: boolean = false;
	@Input() isCategory: boolean = false;
	@Input() isDish: boolean = false;

	@ContentChild('optionsBefore') optionsBefore: TemplateRef<any>;
	@ContentChild('optionsAfter') optionsAfter: TemplateRef<any>;
	@ViewChild('activeColumn') activeColumn: ElementRef;
	@ViewChild('deleteButton') deleteButton: ElementRef;

	@Output() selectedRows = new EventEmitter();

	user = this.authService.auth.user;
	initialized = false;
	canEdit = false;
	canCreate = false;
	canRemove = false;
	open = false;
	showColumnsArray: boolean = false;
	allColumns: Table.IColumn[] = [];
	columns: string[] = [];
	rowsSelected: any[] = [];
	rowsSelectedToDelete: any[] = [];
	bulkDeleteParams: string
	smallScreen: boolean;
	currentTime: number = null;

	elementType: QRCodeElementType = 'canvas';
	dummyImage: string = 'assets/image.jpg';

	constructor(
		private dialog: MatDialog,
		private authService: AuthService,
		private userService: UserService,
		private toasterService: ToasterService,
		private router: Router,
		private breakpointObserver: BreakpointObserver,
		private crudService: CrudService,
		private spinnerService: SpinnerService,
		@Optional() private dialogRef: MatDialogRef<any>
	) { }

	ngOnInit(): void {

		this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(screen => {
			this.smallScreen = screen.matches
		});
		if (
			this.activeOptions?.crud &&
			isNoEmptyArray(this.activeOptions.crud as any[])
		) {
			this.rowsSelected = this.activeOptions.crud as any[];
		}

		if (this.chooseColumns) {
			this.allColumns = [...this.crud.table.columns];
		}

		this.getTime();
	}

	public clickAction(): void {

		if (this.isMenu && this.user?.menuCount == this.user?.maxMenuCount) {
			this.toasterService.show('You have already consumed the maximum number of menus', 'Info')
			return
		}
		this.newModel();
	}

	ngAfterViewInit(): void {
		this.checkPrivileges();
		this.canEdit = true
		this.canRemove = true
		if (this.canEdit || this.canRemove) {
			// this.crud.table.columns.splice(0, 0, {
			// 	field: '__bulkDelete',
			// 	label: this.deleteButton.nativeElement,
			// 	template: this.bulkDelete,
			// 	__checked: true,
			// });
			this.crud.table.columns.push({
				field: '__options',
				label: 'Actions',
				template: this.options,
				// __checked: true,
			});
		}
		setTimeout(() => {
			this.initialized = true;
		}, 1);
		if (this.activeOptions?.active) {
			this.crud.table.columns.splice(0, 1, {
				field: '__active',
				label: 'Active',
				template: this.activeColumn,
			});
		}
	}

	checkPrivileges() {
		this.canCreate = this.crud.canCreate;
		this.canEdit = this.crud.canEdit;
		this.canRemove = this.crud.canRemove;
		if (!isNullOrUndefined(this.crud.createPermission)) {
			this.canCreate =
				this.canCreate &&
				// this.authService.hasPermission(this.crud.createPermission);
				true;
		}
		if (!isNullOrUndefined(this.crud.editPermission)) {
			this.canEdit =
				this.canEdit &&
				// this.authService.hasPermission(this.crud.editPermission);
				true;
		}
		if (!isNullOrUndefined(this.crud.deletePermission)) {
			this.canRemove =
				this.canRemove &&
				// this.authService.hasPermission(this.crud.deletePermission);
				true;
		}
	}

	async editModel(item: any) {
		this.checkPrivileges();
		this.canEdit = true
		if (!this.canEdit) {
			return;
		}
		if (isValidFunction(this.crud.edit)) {
			return this.crud.edit(item);
		}
		if (!this.popUpVersion) {
			this.spinnerService.show();

			await this.router.navigate([`${this.router.url}/edit/${item?.id}`]);
			this.spinnerService.hide();
			return;
		}
		await this.dialog
			.open(this.crud.formComponent, {
				minWidth: '50%',
				data: { model: item },
				hasBackdrop: true,
				disableClose: true,
			})
			.afterClosed()
			.toPromise();
		this.reloadPaging();
	}

	async destroyModel(item: any) {
		this.spinnerService.show()
		this.checkPrivileges();
		this.canRemove = true
		if (!this.canRemove) {
			this.spinnerService.hide()
			return;
		}
		const result = await this.dialog
			.open(AlertComponent, {
				data: {
					title: this.alertMessage?.title
						? this.alertMessage?.title
						: 'admin_area.alert',
					question: this.alertMessage?.question
						? this.alertMessage?.question
						: 'Do you want to delete it?',
				},
				disableClose: true,
				hasBackdrop: true,
				minWidth: '35%',
				// width: isNullOrUndefined(this.alertMessage) ? 'auto' : '25%',
			})
			.afterClosed()
			.toPromise();
		if (result) {
			const remove = this.crud.remove(item);
			if (!isNullOrUndefined(remove) && remove instanceof Promise) {
				await remove;
			}
			if (this.isMenu) {
				this.user.menuCount -= 1;
				this.user = new User(await this.userService.save(this.user, this.authService.projectId))
			}
			this.toasterService.show(
				`The ${this.deleteMessage} was deleted successfully`,
				'Success'
			);
			this.spinnerService.hide()
			this.reloadPaging();
			this.rowsSelectedToDelete = [];
		}
		this.spinnerService.hide()
	}

	async newModel() {
		this.checkPrivileges();
		// if (!this.canCreate) {
		// 	return;
		// }
		if (isValidFunction(this.crud.newModel)) {
			return this.crud.newModel();
		}
		if (!this.popUpVersion) {
			this.router.navigate([`${this.router.url}/create`]);
			return;
		}
		await this.dialog
			.open(this.crud.formComponent, {
				minWidth: '50%',
				hasBackdrop: true,
				disableClose: true,
			})
			.afterClosed()
			.toPromise();
		this.reloadPaging();
	}

	async reloadPaging() {
		await this.crud.table.paging.loadData();
		this.crud.table.paging.response.data.map((doc: { [key: string]: any; id: string; }) =>
			this.crudService.overwriteFieldsIfHaveTo(doc)
		);
	}

	emitColumn(value: string): void {
		if (this.crud.table.columns.length < 6) {
			let columnToAdd = this.allColumns.filter(
				(column) => column.label === value
			)[0];

			if (!columnToAdd) {
				return;
			}

			this.crud.table.columns.splice(
				this.allColumns.indexOf(columnToAdd),
				0,
				columnToAdd
			);
			columnToAdd.__checked = true;
			return;
		}

		this.toasterService.showError('algo paso');
	}

	removeItemFromColumn(value: string): void {
		const indexToRemove = this.crud.table.columns.filter(
			(column: { label: string; }) => column.label === value
		)[0];
		if (indexToRemove) {
			this.crud.table.columns.splice(
				this.crud.table.columns.indexOf(indexToRemove),
				1
			);
			indexToRemove.__checked = false;
		}
	}

	firstFiveColumns() {
		this.allColumns.forEach((column, index) => {
			if (index < this.columnLimit) {
				column.__checked = true;
				return;
			}

			column.__checked = false;
			this.allColumns.splice(index, 1);
		});
	}

	addOrRemoveRows(item: any) {
		const index = this.rowsSelected.findIndex(
			(row) => row?.id === item?.id
		);

		if (index < 0) {
			this.rowsSelected.push(item);
			return;
		}

		this.rowsSelected.splice(index, 1);
	}

	addOrRemoveRowsToDelete(item: any) {
		const index = this.rowsSelectedToDelete.findIndex(
			(row) => row?.id === item?.id
		);

		if (index < 0) {
			this.rowsSelectedToDelete.push(item);
			return;
		}

		this.rowsSelectedToDelete.splice(index, 1);
	}

	isRowChecked(item: any, isBulkDelete: boolean = false): boolean {
		if (!isBulkDelete) {
			if (!item?.id || !this.activeOptions?.crud) {
				return false;
			}

			const isFound = (this.activeOptions.crud as any[]).some(
				(element) => element?.id === item?.id
			);
			return isFound;
		}

		if (!item?.id || !this.rowsSelectedToDelete) {
			return false;
		}

		const isFound = (this.rowsSelectedToDelete as any[]).some(
			(element) => element?.id === item?.id
		);
		return isFound;
	}

	emitRows(): void {
		this.dialogRef.close({ data: { crud: this.rowsSelected } });
	}

	async deleteItems() {
		const elementCount = this.rowsSelectedToDelete.length;
		const elementsOrElement = elementCount > 1 ? 'elements' : 'element';
		this.bulkDeleteParams = `${elementCount} ${elementsOrElement}`

		const result = await this.dialog
			.open(AlertComponent, {
				data: {
					title: 'admin_area.delete_bulk_title',
					question: 'admin_area.want_delete_sure_desc',
					param: this.bulkDeleteParams
				},
				hasBackdrop: true,
				disableClose: true,
				width: isNullOrUndefined(this.alertMessage) ? 'auto' : '25%',
			})
			.afterClosed()
			.toPromise();
		if (result) {
			if (isNoEmptyArray(this.rowsSelectedToDelete)) {
				this.rowsSelectedToDelete.forEach(async (item) => {
					const remove = this.crud.remove(item);
					if (
						!isNullOrUndefined(remove) &&
						remove instanceof Promise
					) {
						await remove;
						this.reloadPaging();
					}
				});
			}
			this.toasterService.show(
				`The ${this.deleteBulkMessage} were deleted successfully`,
				'Success'
			);
			this.rowsSelectedToDelete = [];
		}
	}

	getTime() {
		const startTime = firebase.firestore.Timestamp.now();
		const utc = moment().utcOffset();
		this.currentTime =
			moment(startTime.seconds * 1000)
				.subtract(utc, 'minutes')
				.toDate()
				.getTime() / 1000;
	}

	copyUrl(url: string) {
		navigator.clipboard.writeText(url).then(() => {
			this.toasterService.show('The url has been copied', 'Success');
		}).catch(err => {
			this.toasterService.showError('There was a problem copying the url');
		});
	}

	redirectToMenu(url: string): void {
		window.open(url, '_blank');
	}

	saveQaAsImage(qrElement: any) {
		let parentElement = null

		if (this.elementType === "canvas") {
			parentElement = qrElement.qrcElement.nativeElement
				.querySelector("canvas")
				.toDataURL("image/png")
		} else if (this.elementType === "img" || this.elementType === "url") {
			parentElement = qrElement.qrcElement.nativeElement.querySelector("img").src
		} else {
			this.toasterService.showError('There was a problem generating the image');
			return
		}

		if (parentElement) {
			let blobData = this.convertBase64ToBlob(parentElement)
			const blob = new Blob([blobData], { type: "image/png" })
			const url = window.URL.createObjectURL(blob)
			const link = document.createElement("a")
			link.href = url
			link.download = 'qrMenu_' + this.generateRandomName(12);
			link.click()
		}
	}

	private convertBase64ToBlob(Base64Image: string) {
		const parts = Base64Image.split(";base64,")
		const imageType = parts[0].split(":")[1]
		const decodedData = window.atob(parts[1])
		const uInt8Array = new Uint8Array(decodedData.length)
		for (let i = 0; i < decodedData.length; ++i) {
			uInt8Array[i] = decodedData.charCodeAt(i)
		}
		return new Blob([uInt8Array], { type: imageType })
	}

	generateRandomName(length: number): string {
		const char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let response = '';
		for (let i = 0; i < length; i++) {
			response += char.charAt(Math.floor(Math.random() * char.length));
		}
		return response;
	}

	onError(event: Event) {
		const imgElement = event.target as HTMLImageElement;
		imgElement.src = this.dummyImage;
	}
}
