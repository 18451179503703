import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})

export class ApiService {

	apiUrl = environment.API_URL;

	constructor(
		private http: HttpClient
	) { }

	post(url: string, data: IHttpData, headers?: { [key: string]: string }) {
		return this.http.post<IApiResponse>(`${this.apiUrl}${url}`, data, { headers }).toPromise();
	}

	put(url: string, data?: IHttpData, headers?: { [key: string]: string }) {
		return this.http.put<IApiResponse>(`${this.apiUrl}${url}`, data, { headers }).toPromise();
	}

	get(url: string, options?: HttpParams) {
		return this.http.get<IApiResponse>(`${this.apiUrl}${url}`, { params: options }).toPromise();
	}

	delete(url: string, body?: IHttpData) {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: body
		}

		return this.http.delete<IApiResponse>(`${this.apiUrl}${url}`, options).toPromise();
	}

	changePaymentMethod(data: IHttpData, headers?: { [key: string]: string }) {
		return this.http.post<IApiResponse>(`${this.apiUrl}/payment/editPaymentMethod`, data, headers);
	}
}

export interface IHttpData {
	[key: string]: any;
}

export interface IApiResponse {
	[key: string]: any;
	data: any;
	error: any;
	message: string;
	statusCode: 200;
}
