import { Injectable } from '@angular/core';
import { DbService } from '../db/db.service';
import { StorageService } from '../storage/storage.service';
import { Category } from 'src/app/models/firebase/category';
import { getFileNameExtension, isNullOrUndefined } from '@pineapplelab/util';
import { Project } from 'src/app/models/firebase/project';
import { Menu } from 'src/app/models/firebase/menu';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})

export class MenuService {

	url = environment?.URL;

	constructor(
		private db: DbService,
		private storageService: StorageService,
	) { }

	async save(model: Menu, projectId: string) {

		if (isNullOrUndefined(model.id)) {
			model.id = this.db.createId();
			model.qrCode = this.url + 'menu/' + model.id
		}

		// await this.saveOrRemoveImagesIfNecessary(model, projectId);
		const json = model.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Menu.PATH).doc(model.id).set(json, { merge: true });

		return json;
	}

	delete(projectId: string, modelId: string) {
		const json = { is_deleted: true, deleted_at: this.db.serverTimeStamp() };
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(Menu.PATH).doc(modelId).set(json, { merge: true });
	}

	// private async saveOrRemoveImagesIfNecessary(model: Menu, projectId: string) {
	// 	const commonPath = `${Project.PATH}/${projectId}/${Menu.PATH}/${model.id}`;
	// 	let promisePool: Promise<any>[] = [];

	// 	const pathImage = `${commonPath}/menu.${getFileNameExtension(model.__fileImage.name)}`;

	// 	promisePool.push(this.storageService.uploadFile(pathImage, model.__fileImage, { contentType: 'image/jpeg' }));

	// 	[model.image] = await Promise.all(promisePool);
	// }

	docReference(projectId: string, menuId: string) {
		const path = this.db.root.collection(Project.PATH).doc(projectId).collection(Category.PATH).doc(menuId).ref.path;
		return this.db.firestore.doc(path);
	}

	async getMenu(projectId: string, menuId: string): Promise<Menu> {
		const snapshot = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(Menu.PATH).where('is_deleted', '==', false).where('id', '==', menuId).get();
		return snapshot.docs.map(doc => new Menu(doc.data()))[0];
	}


}
