import { Component, Input, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';

register();

@Component({
	selector: 'app-carousel-image',
	templateUrl: './carousel-image.component.html',
	styleUrls: ['./carousel-image.component.scss'],
})

export class CarouselImageComponent implements OnInit {

	@Input() images: string[] = [];

	constructor(
	) { }

	ngOnInit(): void {
	}
}
