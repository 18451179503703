import { Injectable, } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined, isValidString } from '@pineapplelab/util';
import { catchError, map, switchMap, tap, share, filter, take, mergeAll } from 'rxjs/operators';
import { throwError, of, Subscription, Observable, BehaviorSubject, from } from 'rxjs';
import { BrowserStorage } from 'src/app/models/browserStorage';
import { ToasterService } from '../toaster/toaster.service';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({
	providedIn: 'root'
})
export class HttpClientService {

	constructor(
		private toasterService: ToasterService,
		private http: HttpClient
	) { }

	apiUrl = environment.API_URL;

	private ongoingRequests: Subscription[] = [];
	private refreshTokenRequest: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.interceptAsync(req, next)).pipe(mergeAll());
	}

	async interceptAsync(req: HttpRequest<any>, next: HttpHandler): Promise<Observable<HttpEvent<any>>> {
		if (req.body?.isAdminGenerated || req.url.endsWith(`${this.apiUrl}/auth/refreshToken`) || req.url.indexOf('/assets/') > -1) {
			return next.handle(req).pipe(catchError((error) => throwError(error)));
		}

		const token = BrowserStorage.get('userToken');

		if (isValidString(token) && isNullOrUndefined(req.headers.get('Authorization'))) {
			const decodedToken: any = jwtDecode(token);
			const expiresAt = decodedToken.exp * 1000;
			const seventyTwoHoursAgo = Date.now() - (72 * 60 * 60 * 1000);
			if (seventyTwoHoursAgo >= expiresAt) {
				this.refreshTokenRequest.next(null);
				await this.handleTokenExpiration();
				return of(null);
			} else if (Date.now() >= expiresAt) {
				if (this.refreshTokenRequest.getValue() === null) {
					this.refreshTokenRequest.next(false);
					this.http.post(`${this.apiUrl}/auth/refreshToken`, {}, {
						headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
					}).subscribe(
						(response: any) => {
							const newToken = response.data.accessToken;
							BrowserStorage.set('userToken', newToken);
							this.refreshTokenRequest.next(newToken);
						},
						async (err) => {
							// Handle errors from the refresh token request
							this.refreshTokenRequest.next(null);
							await this.handleTokenExpiration();
						},
						() => {
							// Complete
							this.refreshTokenRequest.next(null);
						}
					);
				}
				return this.refreshTokenRequest.pipe(
					filter((token) => token !== false),
					take(1),
					switchMap((newToken) => {
						const clonedRequest = req.clone({
							headers: req.headers.set('Authorization', `Bearer ${newToken}`),
						});
						return next.handle(clonedRequest);
					})
				);
			}

			return this.handleRequestWithToken(req, next, token);
		} else {
			return next.handle(req).pipe(
				catchError((err) => throwError(err)),
				map((event: HttpEvent<any>) => event)
			);
		}
	}

	private cancelOngoingRequests() {
		this.ongoingRequests.forEach((request) => request.unsubscribe());
		this.ongoingRequests = [];
	}

	private async handleTokenExpiration() {
		this.cancelOngoingRequests();
		BrowserStorage.delete('userToken');
		this.toasterService.showError('For security reasons we have logged you out. Please log back in to continue.');
		await new Promise((resolve) => setTimeout(resolve, 5000));
		window.location.href = window.location.origin + '/auth/login';
	}

	private handleRequestWithToken(req: HttpRequest<any>, next: HttpHandler, token: string) {
		const clonedRequest = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${token}`),
		});

		return new Observable<HttpEvent<any>>((observer) => {
			const subscription = next.handle(clonedRequest).subscribe(
				(event) => observer.next(event),
				(err) => {
					if (err.status === 401) {
						this.handleTokenExpiration();
					}
					observer.error(err);
				},
				() => observer.complete()
			);

			this.ongoingRequests.push(subscription);

			return () => {
				const index = this.ongoingRequests.indexOf(subscription);
				if (index !== -1) {
					this.ongoingRequests.splice(index, 1);
				}
			};
		});
	}

}
