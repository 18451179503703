<div fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="space-between center" class="p-0" fxLayoutGap="16px">
	<ng-container [ngSwitch]="paging.orderDataType">
		<ng-container *ngSwitchDefault [ngTemplateOutlet]="normalSearch"></ng-container>
		<ng-container *ngSwitchCase="OrderDataType.timestamp" [ngTemplateOutlet]="dateSearch"></ng-container>
	</ng-container>

</div>

<ng-template #normalSearch>
	<mat-form-field appearance="outline" class="top">
		<mat-icon class="top-icon" mat-icon-button matPrefix>search</mat-icon>
		<input class="top-input" type="text" [(ngModel)]="paging.search" (input)="changeSearch(paging.search)"
			matTooltip="{{searchPlaceholder}}" matInput placeholder="{{placeholder}}">
	</mat-form-field>
</ng-template>

<ng-template #dateSearch>

	<mat-form-field appearance="outline" class="top" (click)="picker.open()">
		<mat-date-range-input [rangePicker]="picker">
			<input [(ngModel)]="start" name="searchDate" matStartDate (dateChange)="setSearchDate()"
				placeholder="Start date">
			<input [(ngModel)]="end" name="searchDate" (dateChange)="setSearchDate()" matEndDate placeholder="End date">
		</mat-date-range-input>

		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

		<mat-date-range-picker touchUi #picker>
			<mat-datepicker-actions>
				<button mat-button matDatepickerCancel>Cancel</button>
				<button mat-raised-button color="primary" matDatepickerApply>Done</button>
			</mat-datepicker-actions>
		</mat-date-range-picker>
	</mat-form-field>

</ng-template>
