<div fxLayout="row" fxLayoutAlign="center center" class="container h-100v">

	<div fxLayout="column" fxLayoutAlign="center center" class="w-100 info-container border-primary" fxLayoutGap="20px">

		<div fxLayout="row" fxLayoutAlign="center center">
			<h2 class="ma-auto">Payment Completed!</h2>
			<mat-icon class="text-primary">task_alt</mat-icon>
		</div>

		<p>Thank you for your purchase. Now you can enjoy all the benefits of Webnu. You will be redirected to the dashboard shortly. Welcome to the full experience!</p>

		<app-spinner></app-spinner>

	</div>

</div>
