<div fxFill fxLayout="column">
    <app-header></app-header>
    <mat-sidenav-container class="h-100" style="background-color: #EFF1F4;">
        <mat-sidenav #sidenav mode="side" opened class="bg-background-card menu-size">
            <app-side-bar></app-side-bar>
        </mat-sidenav>
        <mat-sidenav-content #sidenavContent fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
            <span class="ghost-span"></span>
            <div class="general-container">
                <span class="ghost-span"></span>
                <br>
                <div>
                    <ng-content></ng-content>
                </div>
                <span class="ghost-span"></span>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
