import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Base } from 'src/app/models/base';

@Component({
	selector: 'app-mat-form-field',
	templateUrl: './mat-form-field.component.html',
	styleUrls: ['./mat-form-field.component.scss']
})

export class MatFormFieldComponent implements OnInit {

	@Input() model: Base.IBase;
	@Input() property: keyof MatFormFieldComponent['model'];
	@Input() label: string = '';
	@Input() hint: string = '';
	@Input() disabled: boolean = false;
	@Input() inputName: string = '';
	@Input() placeholder: string = '';
	@Input() type: string = 'text';
	@Input() min: string = '0';
	@Input() max: string = '';
	@Input() hideCleanButton: boolean = false;
	@Input() appearance: MatFormFieldAppearance = "fill";
	@Input() maxRows: number = 5;
	@Input() minRows: number = 1;
	@Input() showSeePassword: boolean = true;
	@Input() time: string = '';
	@Input() validateOnInput: boolean = true;

	@Output() onCleanProperty = new EventEmitter();
	show: boolean = false;

	constructor() { }

	ngOnInit(): void {
	}

	cleanProperty() {
		this.model[this.property] = '';
		this.model.isValid();
		this.onCleanProperty.emit();
	}
}


