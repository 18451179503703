import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AppDatePipe } from './app-date/app-date.pipe';
import { SafeUrlPipe } from './safeUrl/safe-url.pipe';

@NgModule({
	declarations: [
		AppDatePipe,
		SafeUrlPipe,
	],
	imports: [
		CommonModule
	],
	exports: [
		AppDatePipe,
		SafeUrlPipe,
	],
	providers: [
		DatePipe
	]
})
export class PipesModule { }
