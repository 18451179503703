<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">

	<div fxLayout="row" fxLayoutAlign="center stretch">
		<a routerLink="/" fxLayoutAlign="center">
			<img class="w-100" src="../../../assets/digital-menu.png" alt="loading-logo">
		</a>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
		<p> el copyyright 2023</p>

		<div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="3vw">
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3vw">
				<span class="text-accent pointer mobile_margin" fxFlex fxLayout="row" fxLayoutAlign="end center"
					math-list-item>
					politica de privacidad
				</span>
				<span class="text-accent pointer mobile_margin" fxFlex fxLayout="row" fxLayoutAlign="end center"
					math-list-item>
					terminos y condiciones
				</span>
				<span class="text-accent pointer mobile_margin" fxFlex fxLayout="row" fxLayoutAlign="end center"
					math-list-item>
					contacto
				</span>
			</div>
		</div>

	</div>

</mat-toolbar>
