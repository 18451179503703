import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Data, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { isValidString } from '@pineapplelab/util';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

export const authGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

	const authService = inject(AuthService);
	const toasterService: ToasterService = inject(ToasterService);
	const router = inject(Router);

	return isLoggedIn(route.data, authService, toasterService, router);
};

async function isLoggedIn(routeData: Data, authService: AuthService, toasterService: ToasterService, router: Router): Promise<boolean> {
	const isLogged = await authService.isSigned();
	let { onlyLoggedOut, onDenialRedirect, onDenialMessage } = routeData;

	if (isLogged && onlyLoggedOut) {
		onDenialRedirect = isValidString(onDenialRedirect) ? onDenialRedirect : '/';
		onDenialMessage = isValidString(onDenialMessage) ? onDenialMessage : 'You cannot access this route';

		toasterService.showError(onDenialMessage);
		router.navigateByUrl(onDenialRedirect);
		return false;
	}

	if (!isLogged && !onlyLoggedOut) {
		onDenialRedirect = isValidString(onDenialRedirect) ? onDenialRedirect : '/auth/login';
		onDenialMessage = isValidString(onDenialMessage) ? onDenialMessage : 'You cannot access this route, log in first!';

		toasterService.showError(onDenialMessage);
		router.navigateByUrl(onDenialRedirect);
		return false;
	}

	return true;
}
