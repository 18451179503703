import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalPaging } from '@pineapplelab/paging';
import { isNullOrUndefined } from '@pineapplelab/util';
import { isObject } from 'lodash';
import { Category } from 'src/app/models/firebase/category';
import { Dishes } from 'src/app/models/firebase/dishes';
import { Project } from 'src/app/models/firebase/project';
import { User } from 'src/app/models/firebase/user';
import { ObjectSubmodel } from 'src/app/models/objectSubmodel';
import { Paging } from 'src/app/models/paging';
import { Table } from 'src/app/models/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DishesService } from 'src/app/services/dishes/dishes.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
	selector: 'app-add-dishes',
	templateUrl: './add-dishes.component.html',
	styleUrls: ['./add-dishes.component.scss']
})

export class AddDishesComponent implements OnInit {

	@ViewChild('activeColumn') activeColumn: ElementRef;
	@ViewChild('image') imageCol: ElementRef;
	@ViewChild('name') name: ElementRef;

	user = this.authService.auth.user;
	model: Category = new Category();
	project = this.authService.projectId;

	dishesPaging = new Paging<Dishes>({
		...Dishes.PAGING,
		size: 10,
		mapElements: doc => this.mapElements(doc),
		sizeOptions: [5, 10, 20],
		orderBy: 'name'
	});

	table = new Table({ paging: this.dishesPaging, columns: [] })

	modelRelation: { [relationId: string]: ObjectSubmodel } = {};
	segmentsToAdd: ObjectSubmodel[] = [];
	pagesMount: number = 0;

	modelPreview: any = null;
	changeModel: any = null;
	changeClick: boolean = false;
	changeView: boolean = false;
	videoUrl: string = null;

	constructor(
		private dialogRef: MatDialogRef<AddDishesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { model: Category, modelRelation: { [relationId: string]: ObjectSubmodel } },
		protected toasterService: ToasterService,
		protected authService: AuthService,
		private dishesService: DishesService,
		public dialog: MatDialog,
	) { }

	ngOnInit(): void {
		this.dishesPaging.orderOptions = [
			{ field: 'name', label: 'Name', placeholder: 'Filter by name', dataType: LocalPaging.dataType.string },
		];

		this.dishesPaging.preconditions = [
			{ fieldPath: 'is_deleted', filterOp: '==', value: false },
			{ fieldPath: 'created_by', filterOp: '==', value: this.user?.id },
		];

		this.dishesPaging.subCollections = [
			{ collection: Project.PATH, docKey: this.project },
		];

		this.dishesPaging.loadData();
	}

	ngAfterViewInit(): void {
		this.table.columns.splice(0, 0, { field: '__active', label: '', cssClass: 'demo-position', template: this.activeColumn });
		this.table.columns.splice(1, 0, { field: 'coverImage', label: 'Image', template: this.imageCol });
		this.table.columns.splice(2, 0, { field: 'name', label: 'Name', template: this.name });

		if (isObject(this.data) && isObject(this.data.model)) {
			this.loadModelData();
		}
		this.table.initialized = true
	}

	mapElements(doc: any) {
		const dishes: Dishes & { active?: boolean } = new Dishes(doc);

		dishes.active = !isNullOrUndefined(this.modelRelation[doc.id]);

		return dishes;
	}

	addOrRemoveRelation(item: Dishes) {
		const index = this.segmentsToAdd.findIndex(c => c.id === item.id);
		if (index !== -1) {
			this.segmentsToAdd.splice(index, 1);
			delete this.modelRelation[item.id];
			return;
		}

		let newRelation = new ObjectSubmodel()

		newRelation.id = item.id;
		newRelation.name = item.name;
		newRelation.isActive = true;
		newRelation.reference = this.dishesService.docReference(this.project, item.id);

		this.segmentsToAdd.push(newRelation);
		this.modelRelation[item.id] = newRelation;
	}

	loadModelData() {
		this.modelRelation = this.data.modelRelation;
		this.model = this.data.model;
		this.segmentsToAdd.push(...this.model.dishes)

	}

	async saveInClass() {
		this.model.dishes = [...this.segmentsToAdd];
		const isEmpty = Object.keys(this.modelRelation).length === 0;
		if (!isEmpty) {
			this.toasterService.show('dish added', 'Success');
			this.dialogRef.close();
		}
		if (isEmpty) {
			this.toasterService.show('dishes no added', 'Info');
		}
	}

}
