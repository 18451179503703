import { Injectable } from '@angular/core';
import { Project } from '../../models/firebase/project';
import { User } from '../../models/firebase/user';
import { DbService } from '../db/db.service';
import { AuthService } from '../../services/auth/auth.service';
import { camelToSnakeCase, getFileNameExtension, isNoEmptyArray, isNullOrUndefined } from '@pineapplelab/util';
import { StorageService } from '../storage/storage.service';
import firebase from 'firebase/compat/app';
import { ApiService } from '../api/api.service';
// import { ObjectSubmodel } from '../../models/objectSubmodel';
// import { ValidationField } from '../../enums/validation-field';
// import { PaymentService } from '../payment/payment.service';
// import { PaymentMethod } from '../../models/firebase/paymentMethod';
import * as moment from 'moment';
// import { PurchaseService } from '../purchase/purchase.service';
// import { Purchase } from '../../models/firebase/purchases';
// import { MailDto } from '../../models/dto/mail.dto';
// import { EmailTemplate } from '../../enums/email-template.enum';
// import { MailService } from '../mail/mail.service';
// import { NotificationConfigService } from '../notification-config/notification-config.service';
// import { NotificationMeans } from '../../enums/NotificationMeans.enum';
// import { FeedHistory } from '../../models/firebase/feed-history';
// import { InteractionTimelineItem } from '../../models/firebase/interactionTimelineItem';
// import { UserRole } from '../../models/firebase/userRole';
// import { UpdateUserDTO } from '../../models/dto/update-user.dto';
import { DocumentReference, DocumentData } from "@angular/fire/firestore";
import { UpdateUserDTO } from 'src/app/models/dto/update-user.dto';
// import { IFriendAndNoFriendGroup } from '../../interfaces';

@Injectable({
	providedIn: 'root'
})

export class UserService {

	constructor(
		private db: DbService,
		private authService: AuthService,
		private storageService: StorageService,
		private api: ApiService,
		// private paymentService: PaymentService,
		// private purchaseService: PurchaseService,
		// private mailService: MailService,
		// private notificationConfigService: NotificationConfigService
	) { }

	async saveUser(model: User, projectId: string) {

		if (isNullOrUndefined(model.id)) {
			throw new Error("User id is required");
		}

		const json = model.toJson(false, ['firstName', 'bannerVideo', 'avatar', 'country', 'state', 'city']);
		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(model.id).set(json, { merge: true });
		return json;
	}

	async saveBannersImages(model: User, projectId: string) {
		const commonPath = `${Project.PATH}/${projectId}/${User.PATH}/rest-banner/${model.id}`;
		model.imageBanner = []

		for (const imageBannerFile of model.__fileBanner) {
			const pathImage = `${commonPath}/${imageBannerFile.name}.${getFileNameExtension(imageBannerFile.name)}`;

			let data

			data = await this.storageService.uploadFile(pathImage, imageBannerFile, { contentType: 'image/jpeg' })

			model.imageBanner.push(data)
		}

		const json = model.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(model.id).set(json, { merge: true });

	}

	async activeOrInactiveBanners(model: User, projectId: string) {
		const json = model.toJson();
		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(model.id).set(json, { merge: true });
		return json;
	}

	async getUserBySlug(projectId: string, slug: string): Promise<User> {
		const user = this.db.root.collection(Project.PATH).doc(projectId).collection(User.PATH).ref.where("slug", "==", slug).get();
		let userData = null;
		if ((await user).docs[0] != null) {
			userData = new User((await user).docs[0].data());
		}
		return userData;
	}

	async changeUserActiveStatus(userId: string, projectId: string, active: boolean): Promise<void> {
		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).set({ active }, { merge: true })
	}

	async updateUser(userId: string, dto: User, isAdminEditing: boolean = false) {
		const updateUserDto = new UpdateUserDTO({ ...dto, isAdminEditing });
		return this.api.put('/user/' + userId, updateUserDto);
	}

	async updatedUser(userId: string, dto: User, projectId: string, allowAvatarChange: boolean = false) {
		if (isNullOrUndefined(userId)) {
			throw new Error("User id is required");
		}
		if (allowAvatarChange && !isNullOrUndefined(dto?.__fileImage)) {
			const commonPath = `${Project.PATH}/${projectId}/${User.PATH}/${dto.id}`;
			const path = `${commonPath}/avatar.${getFileNameExtension(dto.__fileImage.name)}`;
			dto.avatar = await this.storageService.uploadFile(path, dto.__fileImage, { contentType: 'image/jpeg' });
		}

		const json = dto.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).set(json, { merge: true });
		return json;
	}

	async save(model: User, projectId: string) {
		if (isNullOrUndefined(model.id)) {
			model.id = this.db.createId();
		}

		if (!isNullOrUndefined(model?.__fileImage)) {
			await this.saveUserImage(model, projectId);
		}

		const json = model.toJson();

		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(model.id).set(json, { merge: true });
		return json;
	}

	async updateGuestPass(guessPassDuration: number, userId: string, projectId: string) {
		if (isNullOrUndefined(userId)) {
			throw new Error("User id is required");
		}
		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).set({ 'parent_guest_pass_duration': guessPassDuration, 'is_guest': true }, { merge: true });
	}

	async saveUserImage(model: User, projectId: string) {
		const commonPath = `${Project.PATH}/${projectId}/${User.PATH}/${model.id}`;
		if (isNullOrUndefined(model.__fileImage)) {
			return;
		}
		const path = `${commonPath}/avatar.${getFileNameExtension(model.__fileImage.name)}`;
		model.avatar = await this.storageService.uploadFile(path, model.__fileImage, { contentType: 'image/jpeg' });
		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(model.id).set({ avatar: model.avatar }, { merge: true });

	}

	docReference(userId: string, projectId: string) {
		const path = this.db.root.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).ref.path;
		return this.db.firestore.doc(path);
	}

	getUser(userId: string, projectId: string) {
		return this.db.root.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).ref.get();
	}

	getAllUsers(projectId: string, conditions: { field: string, operation: firebase.firestore.WhereFilterOp, value: any }[] = []) {
		let baseQuery: any = this.db.root.collection(Project.PATH).doc(projectId).collection(User.PATH).ref;
		if (!isNoEmptyArray(conditions)) {
			return baseQuery.get();
		}
		conditions.forEach(condition => {
			baseQuery = baseQuery.where(condition.field, condition.operation, condition.value);
		});
		return baseQuery.get();
	}

	async getUsersByIdsList(ids: string[]) {
		const usersListDocs: any[] = await this.db.getBatchDocsForLimitedRequests(this.authService.projectId, User.PATH, ids, "in");
		if (!usersListDocs) {
			return null;
		}
		const usersList = usersListDocs.map(userData => new User(userData));
		return usersList;
	}

	getUsersByIdsListOriginal(ids: string[]) {
		if (!ids || ids.length === 0) {
			return null;
		}
		return this.db.root.collection(Project.PATH).doc(this.authService.projectId).collection(User.PATH).ref.where('id', 'in', ids).get();
	}

	async isUsernameAvailable(username: string, projectId: string) {
		const userRef = await this.db.root.collection(Project.PATH).doc(projectId).collection(User.PATH).ref.where('username_lowercase', '==', username.toLocaleLowerCase()).get();

		if (userRef.docs.length > 0) {
			return false;
		}
		return true;
	}

	async deleteProfileImage(model: User, projectId: string) {
		await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(model.id).set({ avatar: null }, { merge: true });
		model.avatar = null;
	}

	async isEmailAvailable(email: string, projectId: string) {
		const userRef = await this.db.root.collection(Project.PATH).doc(projectId).collection(User.PATH).ref.where('email', '==', email).get();

		if (userRef.docs.length > 0) {
			return false;
		}
		return true;
	}

	saveEmail(projectId: string, modelId: string, email: string) {
		const json = { email };
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(modelId).set(json, { merge: true });
	}

	async getUserByEmail(email: string, projectId: string) {
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).where('email', '==', email).get();
	}

	delete(projectId: string, modelId: string) {
		const json = { is_deleted: true, deleted_at: this.db.serverTimeStamp() };
		return this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(modelId).set(json, { merge: true });
	}

	// async accountValidations(fieldToValidate: ValidationField, field: any = null) {
	// 	switch (fieldToValidate) {
	// 		case ValidationField.creditCardExpired:
	// 			if (isNullOrUndefined(this.authService.auth.user?.stripeCustomerId)) {
	// 				return false
	// 			}
	// 			const paymentData = await this.paymentService.getCurrentPaymentMethod(this.authService.projectId, this.authService.auth.user?.id);
	// 			const currentPaymentMethod = new PaymentMethod(paymentData);
	// 			let paymentDate = moment(`${currentPaymentMethod.expMonth}/01/${currentPaymentMethod.expYear}`, "MM-DD-YYYY")
	// 			let isExpired = paymentDate.diff(moment(), 'days') <= 0;
	// 			const userNotificationConfig = await this.notificationConfigService.getConfigById(this.authService.projectId, this.authService.auth.user?.id);
	// 			const shouldSendEmail = userNotificationConfig?.account === NotificationMeans.EMAIL || userNotificationConfig?.account === NotificationMeans.BOTH;
	// 			if (shouldSendEmail && !isNullOrUndefined(paymentData) && isExpired && !this.authService.auth.user.emailNotification.expiredPaymentMethodEmailSent) {
	// 				const paymentMethodExpiredEmail: MailDto = this.getNotificationEmail(EmailTemplate.PAYMENT_METHOD_EXPIRED);
	// 				this.mailService.sendMail(paymentMethodExpiredEmail);
	// 				this.authService.auth.user.emailNotification.expiredPaymentMethodEmailSent = true;
	// 				await this.save(this.authService.auth.user, this.authService.projectId);
	// 			}
	// 			return isExpired;
	// 		case ValidationField.guestPassActive:
	// 			if (field > this.authService.auth?.user?.parentGuestPassDuration) {
	// 				return true
	// 			}
	// 			return false;
	// 		case ValidationField.membershipExpired:
	// 			const hasMembershipActive = this.authService.auth.user?.currentPlan > -1;
	// 			return hasMembershipActive
	// 		case ValidationField.membershipCancelled:
	// 			const userPurchase = await this.purchaseService.getUserPurchases(this.authService.projectId, this.authService.auth.user?.id);
	// 			const lastUserPurchase = new Purchase(userPurchase);
	// 			return lastUserPurchase.cancelAtPeriodEnd;
	// 	}
	// }

	async getUserFields(userId: string, fields: (keyof User)[], projectId: string) {
		const query = await this.db.firestore.collection(Project.PATH).doc(projectId).collection(User.PATH).doc(userId).get();
		if (isNullOrUndefined(query)) {
			return null;
		}
		const promises = fields.map(async key => {
			const snakeCaseField = camelToSnakeCase(key);
			const dbField = await query.get(snakeCaseField);
			return dbField;
		})
		const result = await Promise.all(promises);
		return result;
	}

	// async updateUserLastLogin(): Promise<void> {
	// 	if (isNullOrUndefined(this.authService?.auth?.user)) {
	// 		return;
	// 	}
	// 	await this.db.firestore.collection(Project.PATH).doc(this.authService.projectId).collection(User.PATH).doc(this.authService.auth?.user?.id).set({ last_login: firebase.firestore.Timestamp.now() }, { merge: true });
	// }

	// async deleteUserData(userId: string, projectId: string): Promise<void> {
	// 	const metadata = {
	// 		deleted_at: this.db.serverTimeStamp(),
	// 		deleted_by: this.authService.auth?.user?.id
	// 	}
	// 	await this.db.firestore.collection(Project.PATH).doc(projectId).collection('usersToDelete').doc(userId).set({ id: userId, ...metadata }, { merge: true });
	// }

	public async getUserDirectInstance(userId: string, projectId: string): Promise<User> {
		const user = await this.getUser(userId, projectId);
		return this.db.jsonToInstance(user, User)[0];
	}

	// async getGroupUsers(usersRef: DocumentReference[], limit?: number): Promise<User[]> {

	// 	if (isNullOrUndefined(limit)) {
	// 		const usersPromise = await Promise.all(usersRef.map(doc => doc.get()));
	// 		return usersPromise.map(item => new User(item.data()));
	// 	};

	// 	const usersRefCopy = usersRef.map(item => item);
	// 	const usersRefSplice = usersRefCopy.splice(0, limit);
	// 	const usersPromise = await Promise.all(usersRefSplice.map(doc => doc.get()));
	// 	return usersPromise.map(item => new User(item.data()));
	// }

	// getUserObjectSubmodel(projectId: string, model: User): ObjectSubmodel {
	// 	return { id: model.id, image: model.avatar, name: model.getFullName, reference: this.docReference(model.id, projectId) };
	// }

	// isDeletedUser(doc: DocumentData): User {
	// 	if (doc.exists) {
	// 		const user = new User(doc.data());
	// 		if (user.isDeleted) {
	// 			return null;
	// 		}
	// 		return user;
	// 	}
	// 	return null;
	// }
}
