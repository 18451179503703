import { FBase } from "./firebase/firebase.base";
import slugify from 'slugify';

export abstract class SlugifyModel extends FBase {

	slug?: string = '';

	override toJson(camelCase = false, onlyFields: string[] = null, onlyFieldsAreRemovedInTheJson = false) {
		const json = super.toJson(camelCase, onlyFields, onlyFieldsAreRemovedInTheJson);
		if (!this.slug || this.slugifyPropsCheckedIsChanges()) {
			this.slugifyProperties();
			json.slug = this.slug
		}
		return json;
	}

	abstract slugProperty(): string[]

	private slugifyProperties() {
		this.slug = slugify(this.slugProperty().join('-'), { lower: true }) + '-' + Date.now();
	}

	private slugifyPropsCheckedIsChanges(): boolean {

		const slugChecked = slugify(this.slugProperty().join('-'), { lower: true });
		const slugWithOutTime = this.slug.substring(0, this.slug.lastIndexOf('-'));

		return slugWithOutTime != slugChecked;
	}
}
