import { Injectable } from '@angular/core';
import { DocumentData } from '@angular/fire/firestore';
import firebase from 'firebase/compat/app';
import { Project } from '../../models/firebase/project';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
	providedIn: 'root'
})

export class DbService {
	firestore = this.angularFirestore.firestore;
	createId = this.angularFirestore.createId;
	root = this.angularFirestore;

	constructor(
		private angularFirestore: AngularFirestore
	) { }

	serverTimeStamp() {
		return firebase.firestore.FieldValue.serverTimestamp();
	}

	async getBatchDocsForLimitedRequests(projectId: string, collection: string, ids: any[], filter: firebase.firestore.WhereFilterOp, property: string = "id", limitData?: number) {
		if (!ids || ids.length === 0) {
			return null;
		}
		const batches: any[] = [];
		while (ids.length) {
			const batch = ids.splice(0, 10);
			batches.push(
				this.root.collection(Project.PATH).doc(projectId).collection(collection)
					.ref
					.where(property, filter, [...batch])
					.limit(limitData)
					.get()
					.then((results: { docs: any[]; }) => results.docs.filter(result => result.exists).map(result => ({ ...result.data() })))
			)
		}
		let batchPromiseResult = await Promise.all(batches)
		let flatContent: any[] = batchPromiseResult.reduce((acc, el) => acc.concat(el), []);
		return flatContent;
	}

	public jsonToInstance<T>(query: firebase.firestore.QuerySnapshot | firebase.firestore.DocumentSnapshot, klass: { new(json?: any): T }): T[] {
		if (!(query instanceof firebase.firestore.QuerySnapshot) && !(query instanceof firebase.firestore.DocumentSnapshot)) {
			throw new Error('Invalid data type for parameter');
		}
		if (query instanceof firebase.firestore.QuerySnapshot) {
			return query.docs.map(item => item.exists ? new klass(item.data()) : null).filter(item => item);
		}
		return query.exists ? [new klass(query.data())] : [];
	}

	isDeleted<T extends { isDeleted: boolean }>(doc: DocumentData, klass: { new(json?: any): T }): T {
		if (doc['exists']) {
			const model = new klass(doc['data']());
			if (model.isDeleted) {
				return null;
			}
			return model;
		}
		return null;
	}
}
