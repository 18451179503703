
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Paging } from '@pineapplelab/paging';
import { isNoEmptyArray, isNotEmptyString, isNullOrUndefined } from '@pineapplelab/util';
import { Table } from 'src/app/models/table';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
	selector: 'app-mat-table',
	templateUrl: './mat-table.component.html',
	styleUrls: ['./mat-table.component.scss'],
	// animations: [
	// 	trigger('detailExpand', [
	// 		state('collapsed', style({ height: '0px', minHeight: '0' })),
	// 		state('expanded', style({ height: '*' })),
	// 		transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
	// 	]),
	// ],
})

export class MatTableComponent implements OnInit {

	@Output() $buttonClicked = new EventEmitter();

	@Input() table: Table<any> & { paging: Paging<any> & { orderByLocally?: string, secondOrderByLocally?: string, secondOrderTypeLocally?: Paging.orderType } };

	@Input() hiddenSearch: boolean;
	@Input() showColDots: boolean = true;
	@Input() hideDeletedRowsButton: boolean = false;
	@Input() hideNewButton: boolean = false;
	@Input() hidePaginator: boolean = false;
	@Input() hideTableFooter: boolean = false;
	@Input() cssClass: string = 'mat-elevation-z8';
	@Input() footerCssClass: string = '';
	@Input() template: TemplateRef<any>;
	@Input() columnLimit: number = 5;
	@Input() hideFilter: boolean = false;
	@Input() expandable: { active: boolean, padding: boolean, template?: TemplateRef<any> } = { active: false, padding: true };
	@Input() styleOptions: { [key: string]: boolean } = {
		'hideHeader': false,
		'hideRowBorders': false,
		'spaceBetween': false,
		'noTableShadow': false,
		'borderRadius': false
	};
	@Input() bulkDelete: boolean = false;
	@Input() chooseColumns: boolean = true;

	@Output() rowClickFunction = new EventEmitter();

	open = false;
	public clickDefined = false;
	ORDER_TYPE = Paging.orderType;
	locallyOrderOptionsMap: { [field: string]: { orderType: Paging.orderType, secondOrderByLocally?: string, secondOrderTypeLocally?: Paging.orderType } } = {};
	expandedElement: any;
	showColumnsArray: boolean = false;
	allColumns: Table.IColumn[] = [];
	columns: string[] = [];
	smallScreen: boolean;

	constructor(
		public media: MediaObserver,
		private toasterService: ToasterService,
		private breakpointObserver: BreakpointObserver,
	) { }

	ngOnInit(): void {

		this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(screen => {
			this.smallScreen = screen.matches
		});
		if (this.chooseColumns) {
			this.allColumns = [...this.table.columns];
			this.firstFiveColumns();
		}
		this.clickDefined = this.rowClickFunction.observers.length > 0;
		this.loadColumnsForPaging();
		this.table.paging.loadData();
	}

	rowClick(args: any) {
		this.rowClickFunction.emit(args);
	}

	emitNewModel(): void {
		this.$buttonClicked.emit();
	}

	switchOrderType(column: Table.IColumn) {
		if (isNullOrUndefined(column?.orderOption)) {
			return;
		}
		if (isNullOrUndefined(this.locallyOrderOptionsMap[this.table.paging.orderByLocally]) && !isNullOrUndefined(this.table.paging.orderByLocally)) {
			this.locallyOrderOptionsMap[this.table.paging.orderByLocally] = { orderType: this.table.paging.orderType, secondOrderByLocally: this.table.paging.secondOrderByLocally, secondOrderTypeLocally: this.table.paging.secondOrderTypeLocally };
		}
		if (isNotEmptyString(this.table?.paging?.orderByLocally)) {
			this.table.paging.orderByLocally = column.field;
		} else {
			this.table.paging.orderBy = column.field;
		}

		const overwriteLocally = { secondOrderByLocally: this.locallyOrderOptionsMap[column.field]?.secondOrderByLocally, secondOrderTypeLocally: this.locallyOrderOptionsMap[column.field]?.secondOrderTypeLocally };
		Object.assign(this.table.paging, overwriteLocally);

		this.table.paging.orderType = this.table.paging.orderType === Paging.orderType.asc ? Paging.orderType.desc : Paging.orderType.asc;

		if (!isNullOrUndefined(this.locallyOrderOptionsMap[column.field]) && this.locallyOrderOptionsMap[column.field].orderType !== this.table.paging.orderType) {
			this.table.paging.secondOrderTypeLocally = this.table.paging.secondOrderTypeLocally === Paging.orderType.asc ? Paging.orderType.desc : Paging.orderType.asc;
		}
		this.table.paging.loadData();
	}

	loadColumnsForPaging() {
		this.columns = this.table.columns.map(column => {
			column.segments = column.field.split('.');
			if (isNoEmptyArray(this.table.paging.orderOptions) && this.table.paging.orderOptions.some(o => o.field === column.field)) {
				column.orderOption = this.table.paging.orderOptions.find(o => o.field === column.field);
			}
			return column.field;
		});
	}

	emitColumn(value: string): void {
		if (this.table.columns.length < 9) {
			let columnToAdd = this.allColumns.filter(column => column.label === value)[0];

			if (!columnToAdd) {
				return;
			}
			this.table.columns.splice(this.table.columns.length - 1, 0, columnToAdd);
			columnToAdd.__checked = true;
			return;
		}
		this.toasterService.showError('algo paso');

	}

	removeItemFromColumn(value: string): void {
		const indexToRemove = this.table.columns.filter(column => column.label === value)[0];
		if (indexToRemove) {
			this.table.columns.splice(this.table.columns.indexOf(indexToRemove), 1);
			indexToRemove.__checked = false;
		}
	}

	firstFiveColumns() {
		this.allColumns.forEach((element, index) => {
			if (index < this.columnLimit) {
				element.__checked = true;
			}
			else {
				if (element.label != "Actions") {
					element.__checked = false;
				}
			}
		});
		const removeCheckedElementsFromTableColumns = this.table.columns.filter(column => !column.__checked);
		removeCheckedElementsFromTableColumns.forEach(element => {
			this.table.columns.splice(this.table.columns.indexOf(element), 1);
		});
		const removeDefaultColumnsFromTable = this.allColumns.filter(column => column.field == 'name' || column.field == '__options' || column.field == 'title' || column.field == '__bulkDelete');
		removeDefaultColumnsFromTable.forEach(element => {
			this.allColumns.splice(this.allColumns.indexOf(element), 1);
		});
	}
}

