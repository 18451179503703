export class UpdateUserDTO {

	firstName: string = null;
	lastName: string = null;
	username: string = null;
	email: string = null;
	city: string = null;
	country: string = null;
	state: string = null;

	constructor(json?: { [key: string]: any }) {
		for (const field in json) {
			if (field in this) {
				(this as any)[field] = (json as any)[field];
			}
		}
	}
}
