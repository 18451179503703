<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center" class="relative">
	<app-paging-search *ngIf="!hiddenSearch" [hideFilter]="hideFilter" fxFlex
		[paging]="table.paging"></app-paging-search>
	<!-- <button mat-icon-button *ngIf="!smallScreen && showColDots" (click)="open = !open"
		[matMenuTriggerFor]="menuCrud">
		<mat-icon>more_vert</mat-icon>
	</button>
	<mat-menu class="bg-primary-darker" #menuCrud="matMenu">
		<span (click)="$event.stopPropagation();" class="padding"><b>Select 4 columns to display:</b></span>
		<div *ngFor="let col of allColumns">
			<mat-checkbox *ngIf="!(col.field == 'name' || col.field == '__options')" value="{{col?.label}}"
				class="padding" #check (click)="$event.stopPropagation();" [checked]="col?.__checked"
				[disabled]="this.table.columns.length=== columnLimit + 1 && col.__checked===false"
				(change)="check.checked ? emitColumn(check.value) : removeItemFromColumn(check.value); loadColumnsForPaging()">
				{{ col?.label }}</mat-checkbox>
		</div>

	</mat-menu> -->
	<div fxLayout="row" fxLayoutAlign="start center" class="position-btn" *ngIf="!template && !hideNewButton; else template">
		<button mat-flat-button class="generic-btn bg-primary" (click)="emitNewModel()">+ New</button>
	</div>
</div>

<br>

<table mat-table [dataSource]="table.paging.response.data" [class]="cssClass"
	[ngClass]="{'no-box-shadow': styleOptions['noTableShadow'], 'add-border-radius': styleOptions['borderRadius'] }"
	[multiTemplateDataRows]="expandable.active" fxFill>
	<ng-container *ngFor="let column of table.columns" [matColumnDef]="column.field">
		<th mat-header-cell *matHeaderCellDef class="{{column.cssClass}}">
			<span fxLayout="row" fxLayoutAlign="start center" (click)="switchOrderType(column)"
				[ngClass]="{'pointer': column?.orderOption}">
				{{column.label}}
				<mat-icon *ngIf="column.field === column?.orderOption?.field" class="text-primary">arrow_drop_up
				</mat-icon>
				<mat-icon class="up-sort-icon text-primary" *ngIf="column.field === column?.orderOption?.field">
					arrow_drop_down</mat-icon>
			</span>
		</th>

		<td mat-cell *matCellDef="let element; let i = index" class="{{column.cssClass}}"
			[ngClass]="{'pointer': expandable.active}">

			<ng-container *ngIf="column.template === undefined || column.template === null"
				[ngTemplateOutlet]="objectValueSpan"
				[ngTemplateOutletContext]="{ object: element, segments: column.segments }"></ng-container>

			<ng-container *ngIf="column.template !== undefined && column.template !== null"
				[ngTemplateOutlet]="column.template"
				[ngTemplateOutletContext]="{ item: element, dataSource: table.paging.response.data, i: i }">
			</ng-container>

		</td>

	</ng-container>


	<tr mat-header-row *matHeaderRowDef="columns" [ngClass]="{'display-none': styleOptions['hideHeader'] }"></tr>

	<tr mat-row *matRowDef="let row; columns: columns; let index = index"
		(click)="rowClick(row); expandedElement = expandedElement === row ? null : row"
		[class.example-expanded-row]="expandedElement === row"
		[ngClass]="{ 'row-deleted': row?.isDeleted, 'display-none': !table.showDeletedRows &&
    row?.isDeleted,'pointer':clickDefined, 'space-under': styleOptions['spaceBetween'],
	'no-border': styleOptions['hideRowBorders'] }">
	</tr>
</table>

<div [class]="footerCssClass" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="8px"
	*ngIf="!hideTableFooter">
	<div *ngIf="hideDeletedRowsButton"></div>

	<app-paginator [paging]="table.paging" [marginRight]="false" [hidePageSize]="table.hidePageSize" fxFlexAlign="end">
	</app-paginator>

</div>

<ng-template #objectValueSpan let-segments='segments' let-object='object'>
	<div class="max-height">
		<span class="word-overflow"
			*ngIf="segments.length === 1 && object !== undefined && object !== null">{{object[segments[0]]}}</span>

	</div>
	<ng-container *ngIf="segments.length > 1 && object !== undefined && object !== null"
		[ngTemplateOutlet]="objectValueSpan"
		[ngTemplateOutletContext]="{ object: object[segments[0]], segments: segments.slice(1) }">
	</ng-container>
</ng-template>
