import { Paging, PagingOrderType } from "@pineapplelab/paging";
import { isObject } from "@pineapplelab/util";
import { ObjectSubmodel } from "../objectSubmodel";
import { IsNotEmpty } from '@pineapplelab/validators';
import { SlugifyModel } from "../slugifyModel";

export class Category extends SlugifyModel {

	@IsNotEmpty({ label: "Title" })
	name: string = null;
	description: string = null;
	dishes: ObjectSubmodel[] = [];
	creator: ObjectSubmodel;

	constructor(json?: { [key: string]: any }) {
		super();
		if (isObject(json)) {
			this.fromJson(json);
		}
	}

	slugProperty(): string[] {
		return [this.name]
	}
}

export namespace Category {

	export const PATH = 'categories';

	export const PAGING = new Paging<Category>(<Paging.IPaging>{
		collection: PATH,
		preconditions: [
			{ fieldPath: 'is_deleted', filterOp: '==', value: false }
		],
		orderOptions: [
			{ field: 'name', label: 'title', placeholder: 'Filter by name', dataType: Paging.dataType.string },
		],
		orderBy: 'created_at',
		orderType: PagingOrderType.desc
	});
}
