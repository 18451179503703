import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { isNoEmptyArray, isNotEmptyString, isNullOrUndefined, isObject, isValidString } from '@pineapplelab/util';


@Component({
	selector: 'app-chips',
	templateUrl: './chips.component.html',
	styleUrls: ['./chips.component.scss']
})

export class ChipsComponent implements OnInit {

	@Input() search: boolean = false;
	@Input() imageError: string = null;
	@Input() showImage: boolean = false;
	@Input() content: (string | { [key: string]: any })[] = [];
	@Input() selectedContent: any[] = [];
	@Input() title = '';
	@Input() field = 'name';
	@Input() label: string = 'Select or type a content...';
	@Input() canCreate = false;
	@Input() disabled: boolean = false;
	@Input() disabledInput: boolean = false;
	@Input() appearance: MatFormFieldAppearance = 'fill';
	@Input() noMargin: boolean = false;
	@Input() configForLegos: boolean = false;

	@Output() selectedChips = new EventEmitter<any>();
	@Output() removedChips = new EventEmitter<any>();

	filteredContent: any[] = this.content;
	filteredValue = '';

	constructor() { }

	ngOnInit(): void {
	}

	filter() {
		const allContent = this.content.filter(content => {
			if (isValidString(content)) {
				return !this.selectedContent.includes(content)
			}
			return this.selectedContent.find(t => t[this.field] === (content as any)[this.field]) === undefined;
		});
		if (!isNotEmptyString(this.filteredValue)) {
			this.filteredContent = allContent;
			return;
		}
		const filterValue = this.filteredValue.toLowerCase();
		this.filteredContent = allContent.filter(content => {
			let text = content;
			if (isObject(text)) {
				text = (content as { [key: string]: any })[this.field];
			}
			return text?.toLowerCase().includes(filterValue);
		});
	}

	remove(content: any): void {
		const index = this.selectedContent.indexOf(content);
		if (index >= 0) {
			this.selectedContent.splice(index, 1);
			this.removedChips.emit(this.selectedContent);
		}
		this.filter();
	}

	selected(event: any): void {
		const value = event.option.value;
		this.selectedContent.push(value);
		this.selectedChips.emit(this.selectedContent);
		this.filter();
	}

	add(event: any) {
		const value = (event.value || '').trim();
		event.input.value = '';
		if (!isNotEmptyString(value) || !this.canCreate) {
			return;
		}
		let content = this.content.find(t => {
			if (isValidString(t) && t === value) {
				return true;
			}
			if (isObject(t) && (t as any)[this.field] === value) {
				return true;
			}
			return false;
		});
		if (!isNullOrUndefined(content) && this.selectedContent.includes(content)) {
			return;
		}
		if (!isNullOrUndefined(content)) {
			this.selectedContent.push(content);
			return;
		}
		if (isNoEmptyArray(this.content) && isValidString(this.content[0])) {
			content = value;
		} else {
			content = {};
			content[this.field] = value;
		}
		this.selectedContent.push(content);
	}

}
